import { Guid } from "guid-typescript";

export interface IRemotePrintDocumentResponse {
    userId: string;
    documents: IRemotePrintDocument[];
}

export interface IRemotePrintDocument {
    documentId: string;
    remotePrinterId: string;
}

export class RemotePrintDocument {
    private readonly _documentId: Guid;
    private readonly _remotePrinterId: Guid;

    public static fromResponse(response: IRemotePrintDocumentResponse): RemotePrintDocument[] {
        return response.documents.map(d => new RemotePrintDocument(d));
    }

    public constructor(document: IRemotePrintDocument) {
        this._documentId = Guid.parse(document.documentId);
        this._remotePrinterId = Guid.parse(document.remotePrinterId);
    }

    public get documentId(): Guid {
        return this._documentId;
    }

    public get remotePrinterId(): Guid {
        return this._remotePrinterId;
    }
}
