import "./style.scss";

import * as React from "react";

import {
    Button,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Dialog,
    Input,
    InputLabel,
    Typography,
} from "@mui/material";
import { stockoutProductModel } from "./stockout-product-model";
import { Icon } from "../../../../common/component/icon/icon";
import { Article } from "../../../model/article";
import { t } from "i18next";
import { notificationModel } from "../../../../common/component/notifications/notification-model";
import { StockoutProductSimulationResult } from "./stockout-product-simulation-result";
import { Stockout } from "../../../model/stockout";
import { ArticleSelect } from "./article-select";

interface IProductStockout {
    contentPrefix: string;
    operationCode: string;
    batchId: number;
    reloadDeliveryOrderList: () => Promise<void>;
}

export abstract class ProductStockout extends React.Component<IProductStockout, {}> {

    public render() {
        return <Dialog
            onClose={() => this.onRequestClose()}
            open={stockoutProductModel.openedModal}
            data-testid="action-stockout-product"
            className={"stockout-popup-dialog"}
            PaperProps={{style: {overflowY: `visible`}}}
        >
            <DialogTitle>{t(`${this.props.contentPrefix}.title`)}</DialogTitle>
            <div className="close">
                <Icon icon="close" onClick={() => this.onRequestClose()} color="primary" />
            </div>
            <DialogContent className={"stockout-popup-content"}>
                { !stockoutProductModel.isSimulationPrepared && this.renderSimulationForm() }
                { stockoutProductModel.isSimulationPrepared && this.renderConfirmationBox() }
            </DialogContent>
        </Dialog>;
    }

    private renderSimulationForm() {
        return <div>
            <DialogContentText className={"stockout-popup-description"}>
                {t(`${this.props.contentPrefix}.selectProductDescription`)}
            </DialogContentText>
            <div>
                <div className="stockout-form-item" data-testid={"stockout-product-select"}>
                    <ArticleSelect selectedItem={stockoutProductModel.selectedProduct}
                                   list={stockoutProductModel.articles}
                                   onSelect={value => this.selectProduct(value)}
                                   label={t(`components.productSelector.label`)}
                                   propertyKey={"stockout-product-selector"}
                                   placeholder={t(`components.productSelector.placeholder`)}
                                   noOptionsMessage={t(`components.productSelector.noResults`)} />
                </div>
                <div className="stockout-form-item">
                    <InputLabel htmlFor="StockoutProductQty" className={"input-label"}>
                        {t("components.productStockout.quantity")}
                    </InputLabel>
                    <Input
                        id="StockoutProductQty"
                        onChange={e => stockoutProductModel.setMissingQuantity(e.target.value)}
                        className={"quantity-selector"}
                        disabled={!stockoutProductModel.hasSelectedProduct}
                    />
                    { stockoutProductModel.hasSelectedProduct ?
                        <Typography className="available-quantity" component="span">
                                    of { stockoutProductModel.selectedProduct?.availableQuantity }
                                </Typography>
                        : undefined}
                </div>
            </div>
            <div className="input-submit-container">
                <Button variant="contained"
                        color={"inherit"}
                        data-testid="stockout-dialog-cancel"
                        onClick={() => this.onRequestClose()}
                        key="cancel-btn">{t(`components.popin.cancel`)}</Button>
                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    data-testid="stockout-dialog-submit"
                    autoFocus key="submit-btn"
                    disabled={!stockoutProductModel.isStockoutFormCompleted}
                    onClick={() => this.simulateStockoutExecution()}>{t(`components.popin.ok`)}</Button>
            </div>
        </div>;
    }

    private renderConfirmationBox() {
        return <div>
            <DialogContentText className={"stockout-popup-description"}>
                {t(`${this.props.contentPrefix}.confirmStockoutProduct`)}
            </DialogContentText>
            <div className="stockout-form-item">
                <Typography className="simulation-label" component="span">
                    {t("components.productStockout.product")}
                </Typography>
                <Typography className="simulation-value" component="span">
                    {stockoutProductModel.selectedProduct!.name}
                </Typography>
            </div>
            <div className="stockout-form-item">
                <Typography className="simulation-label" component="span">
                    {t("components.productStockout.affectedQuantity")}
                </Typography>
                <Typography className="simulation-value" data-testid="simulation-calculated-qty" component="span">
                    {stockoutProductModel.calculatedQuantity}
                </Typography>
            </div>
            <div className="stockout-form-item">
                <Typography className="simulation-label" component="span">
                    {t("components.productStockout.affectedDeliveryOrders")}
                </Typography>
                <Typography className="simulation-value" data-testid="simulation-affected-dos" component="span">
                    {stockoutProductModel.affectedDeliveryOrdersCount}
                </Typography>
            </div>
            <div className="input-submit-container">
                <Button variant="contained"
                        data-testid="stockout-dialog-cancel"
                        onClick={() => this.onRequestClose()}
                        key="cancel-btn">{t(`components.popin.cancel`)}</Button>
                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    data-testid="stockout-dialog-submit"
                    autoFocus key="submit-btn"
                    onClick={() => this.stockoutActionExecution()}>{t(`components.popin.ok`)}</Button>
            </div>
        </div>;
    }

    private onRequestClose() {
        stockoutProductModel.closeAndResetModel();
    }

    private async simulateStockoutExecution() {
        if (!stockoutProductModel.hasSelectedProduct) {
            notificationModel.addErrorMessage(t(`${this.props.contentPrefix}.invalidProduct`));
            return;
        }

        const missingQuantityNumber = Number(stockoutProductModel.missingQuantity);

        if (!stockoutProductModel.isMissingQuantityValid) {
            notificationModel.addErrorMessage(t(`${this.props.contentPrefix}.invalidStockoutQuantity`, {
                maxQuantity: stockoutProductModel.selectedProduct!.availableQuantity,
            }));
            return;
        }

        if (missingQuantityNumber > stockoutProductModel.selectedProduct!.availableQuantity) {
            notificationModel.addErrorMessage(t(`${this.props.contentPrefix}.stockoutQuantityTooBig`, {
                maxQuantity: stockoutProductModel.selectedProduct!.availableQuantity,
            }));
            return;
        }

        const result = await this.simulateAction(stockoutProductModel.deliveryOrderIds, stockoutProductModel.selectedProduct!, missingQuantityNumber);

        stockoutProductModel.setAffectedDeliveryOrdersCount(result.affectedDeliveryOrders);
        stockoutProductModel.setCalculatedQuantity(missingQuantityNumber);
    }

    private async stockoutActionExecution() {
        if (!stockoutProductModel.isSimulationPrepared) {
            throw new Error("Invalid argument exception");
        }

        const stockoutList = await this.executeAction(stockoutProductModel.deliveryOrderIds, stockoutProductModel.selectedProduct!, Number(stockoutProductModel.missingQuantity));

        notificationModel.addSuccessMessage(
            t(`${this.props.contentPrefix}.success`, {stockoutCount: stockoutList.length}),
        );

        this.onRequestClose();
        await this.props.reloadDeliveryOrderList();
    }

    private selectProduct(article: Article | null) {
        stockoutProductModel.setSelectedProduct(article);
    }

    public abstract executeAction(deliveryOrderIds: number[], article: Article, quantity: number): Promise<Stockout[]>;
    public abstract simulateAction(deliveryOrderIds: number[], article: Article, quantity: number): Promise<StockoutProductSimulationResult>;
}
