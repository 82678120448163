import { BulkParcelUploadImportLine } from "../../../../model/bulk-parcel-upload-import-line";
import { t } from "i18next";
import { Pallet } from "../../../../model/pallet";
import { Guid } from "guid-typescript";
import { Parcel } from "../../../../model/parcel";
import { map, uniq } from "lodash";

export class BulkParcelUploadPalletProcessor {
    public process(lines: BulkParcelUploadImportLine[], parcels: Parcel[]) {
        const linesToPalletize = lines.filter(l => l.palletIndexOrEmpty !== "");
        this.validateExternalIdConsistency(linesToPalletize);
        this.validateCarrierCountryConsistency(linesToPalletize);
        this.validateParcelsNotAssignedToOtherPallets(lines, parcels);

        return new Pallet({
            id: Guid.createEmpty().toString(),
            code: lines[0].palletCode ?? "",
            externalId: lines[0].palletExternalId,
            status: (lines[0].palletCode ?? "") === "" ? "New" : "Open",
            parcels: map(uniq(lines.map(p => p.parcelId)), parcelId => {
                const parcel = parcels.find(p => parcelId === p.id);

                if (!parcel) {
                    throw new Error("Parcel not found exception");
                }

                return parcel;
            }),
            maxWeight: 0,
            maxParcelWeight: 0,
            carrier: lines[0].palletCarrier ?? "",
            countryGroup: lines[0].palletCountry ?? "",
            isLabelPrinted: false,
            creationComment: this.mergePalletCreationComments(lines),
            pickingDate: "",
            type: "Regular",
        });
    }

    private validateCarrierCountryConsistency(lines: BulkParcelUploadImportLine[]) {
        if (!lines.every(l => l.palletCarrier === lines[0].palletCarrier)) {
            throw new Error(t("bulkParcelUpload.error.palletCarrierNotConsistent", {
                palletIndex: lines[0].palletIndex,
            }));
        }

        if (!lines.every(l => l.palletCountry === lines[0].palletCountry)) {
            throw new Error(t("bulkParcelUpload.error.palletCountryNotConsistent", {
                palletIndex: lines[0].palletIndex,
            }));
        }
    }

    private validateExternalIdConsistency(lines: BulkParcelUploadImportLine[]) {
        if (!lines.every(l => l.palletExternalId === lines[0].palletExternalId)) {
            throw new Error(t("bulkParcelUpload.error.palletExternalIdNotConsistent", {
                palletIndex: lines[0].palletIndex,
            }));
        }
    }

    private validateParcelsNotAssignedToOtherPallets(lines: BulkParcelUploadImportLine[], parcels: Parcel[]) {
        return lines.filter(line => line.parcelId !== undefined).forEach(line => {
            const parcel = parcels.find(p => p.parcelId === line.parcelId);

            if (parcel !== undefined && parcel.palletCode !== undefined && line.palletCode !== parcel.palletCode) {
                throw new Error(t("bulkParcelUpload.error.parcelAlreadyOnPallet", {
                    parcelId: parcel.parcelId,
                    palletCode: parcel.palletCode,
                }));
            }
        });
    }

    private mergePalletCreationComments(lines: BulkParcelUploadImportLine[]) {
        return uniq(lines
            .map(l => l.palletCreationComment ?? "")
            .filter(c => c !== "")).join(" ");
    }
}
