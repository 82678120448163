import { groupBy } from "lodash";
import { httpGet, httpGetList, httpPost } from "../../common/network/fetch";

import { Batch } from "../model/batch";
import { DeliveryOrder, IDeliveryOrder } from "../model/delivery-order";
import { DeliveryOrderStatusType } from "../../constants/constants";
import { IOperation, Operation } from "../model/operation";
import { IParcel, Parcel } from "../model/parcel";
import { AddAdditionalValueCommandReturn } from "../model/add-additional-values-command-return";
import { AdditionalValues } from "../../common/component/list/import-export-list/additional-values";
import { UrlBuilder } from "./url-builder";
import { IOperationProductExport, OperationProductExport } from "../model/operation-product-export";
import { ICustomOperationRequestItem } from "../../back-office/components/operations/operation-management-import/custom-operation-request-item";

class OperationService {

    public async loadOperations() {
        const dataList = await httpGetList({ url: "/operations" });
        return dataList.map((data: IOperation) => new Operation(data))
            .filter(operation => operation.status !== "Closed");
    }

    public async loadOperation(operationCode: string, displayNotificationError: boolean = false) {
        const data = await httpGet<IOperation>({ url: `/operations/${operationCode}` }, displayNotificationError);
        return new Operation(data);
    }

    public async loadBatches(operationCode: string) {
        const dataList = await httpGetList({ url: `/operations/${operationCode}/batches` });
        return dataList.map((data: Batch) => new Batch(data));
    }

    public async loadDeliveryOrderList(
        operationCode: string,
        batchId: number,
        deliveryOrderStatus?: DeliveryOrderStatusType) {

        const dorUrl = `/operations/${operationCode}/batches/${batchId}${deliveryOrderStatus ? `?deliveryOrderStatus=${deliveryOrderStatus}` : ""}`;

        const [batch, parcelList] = await Promise.all([
            httpGet<{ deliveryOrders: IDeliveryOrder[] }>({ url: dorUrl }),
            httpGetList<IParcel>({ url: `/parcels/operation/${operationCode}/batch/${batchId}` }),
        ]);

        return this.parseDeliveryOrderList(operationCode, batchId, batch.deliveryOrders, parcelList);
    }

    public async loadStockoutOrderList(
        operationCode: string,
        batchId: number) {

        const dorUrl = `/operations/${operationCode}/batches/${batchId}/stockouts`;

        const [batch, parcelList] = await Promise.all([
            httpGet<{ deliveryOrders: IDeliveryOrder[] }>({ url: dorUrl }),
            httpGetList<Parcel>({ url: `/parcels/operation/${operationCode}/batch/${batchId}` }),
        ]);
        return this.parseDeliveryOrderList(operationCode, batchId, batch.deliveryOrders, parcelList);
    }

    public async loadDeliveryOrder(operationCode: string, batchId: number, deliveryOrderId: number) {
        const [data, parcels] = await Promise.all([
            httpGet<IDeliveryOrder>({
                url: `/operations/${operationCode}/batches/${batchId}/deliveryOrder/${deliveryOrderId}`,
            }),
            httpGetList<IParcel>({
                url: `/parcels/operation/${operationCode}/batch/${batchId}/deliveryOrder/${deliveryOrderId}`,
            }),
        ]);
        return this.parseDeliveryOrder(operationCode, batchId, data, parcels);
    }

    public async confirmCancellationRequest(operationCode: string, batchId: number, deliveryOrderId: number) {
        await httpPost({
            url: new UrlBuilder(`/operations/${operationCode}/batches/${batchId}`)
                .setDeliveryOrderId(deliveryOrderId)
                .setConfirmCancellation()
                .build()},
            false);
    }

    public async importAdditionalValues(operationCode: string, additionalValues: AdditionalValues): Promise<AddAdditionalValueCommandReturn> {
        const importUrl = `/operations/${operationCode}/importAdditionalValues`;
        // Rewriting data object to force name to be start without underscore
        const data = {
            type: additionalValues.type,
            items: additionalValues.items.map(item => {
                return {
                    additionalValueLocators: item.locators.map(locator =>  {
                        return {
                            locatorType: locator.locatorName,
                            locatorValue: locator.locatorValue,
                        };
                    }),
                    additionalValueMap: item.valueMap,
                };
            }),
        };

        return await httpPost<AddAdditionalValueCommandReturn>({ url: importUrl, data });
    }

    public async loadProducts(operationCode: string) {
        return (await httpGetList<IOperationProductExport>({
            url: new UrlBuilder(`/operations/${operationCode}`)
                .setProductsList()
                .build(),
        })).map(p => new OperationProductExport(p));
    }

    private parseDeliveryOrderList(operationCode: string, batchId: number, deliveryOrders: IDeliveryOrder[], parcelList?: IParcel[]) {
        // Map giving the parcel list by delivery order.
        const parcelMap = groupBy(parcelList, parcel => parcel.deliveryOrderId);
        return deliveryOrders.map(deliveryOrder =>
            this.parseDeliveryOrder(operationCode, batchId, deliveryOrder, parcelMap[deliveryOrder.id]));
    }

    private parseDeliveryOrder(operationCode: string, batchId: number, deliveryOrder: IDeliveryOrder, doParcelList: IParcel[]) {
        doParcelList = doParcelList || [];
        return new DeliveryOrder(
            operationCode,
            batchId,
            deliveryOrder,
            doParcelList
                .filter(parcel => parcel.status !== "Cancelled")
                .map(p => new Parcel(p, deliveryOrder)));
    }

    public async createCustomOperation(contents: ICustomOperationRequestItem[]) {
        return (await httpPost<string[]>({
            url: new UrlBuilder(`/operations/custom`)
                .build(),
            data: contents,
        }));
    }
}

export const operationsService = new OperationService();
