import React, { useState } from "react";

import { authenticationService } from "../../services/authentication";
import { Navigate, Route, Routes } from "react-router-dom";
import { OperationRouter } from "../components/operation/router";
import { PalletRouter } from "../components/pallet/router";
import { routeReporting } from "./preparation-route-props";
import { VPPreparationMenu } from "../components/preparation-menu/vp-preparation-menu";
import { IHistoryProps } from "../../common/navigation/ihistory-props";
import { Header } from "../../common/component/header/header";
import { HeaderWidgetGroup } from "../../common/component/header/header-widget-group";
import { Breadcrumbs } from "../../common/component/breadcrumbs/breadcrumbs";
import { FrontendBreadcrumbsBuilder } from "../../common/component/breadcrumbs/frontend-breadcrumbs-builder";
import { LoaderIndicatorWidget } from "../../common/component/loader/loader-indicator-widget";
import { context } from "../../context/context";
import { Permission } from "../../context/permission";
import { PalletPickupFormWidget } from "../components/pallet-pickup/pallet-pickup-form-widget";
import { LanguageSelector } from "../../common/component/language-selector/language-selector";
import { UserAccountWidget } from "../components/user-account-widget/user-account-widget";
import { PreparationSectionType } from "./preparation-route-props";
import { BusinessDocumentationList } from "../components/business-documentation/business-documentation-list";
import { SupportTicketWidget } from "../components/support-ticket/support-ticket-widget";
import { WarehouseContextProvider } from "../components/pallet/warehouse-context/warehouse-context-provider";
import { RemotePrintingContextProvider } from "../../common/component/mobile-connector/remote-printing/remote-printing-context";
import { MobileConnectorWidget } from "../../common/component/mobile-connector/mobile-connector-widget";
import { BarcodeScannerTestPage } from "../components/barcode-scanner-test-page/barcode-scanner-test-page";
import { t } from "i18next";

import "../../style/style.scss";
import "./style.scss";

export const PreparationLayout = (props: IHistoryProps) => {
    const [defaultRouteExpanded, setDefaultRouteExpanded] = useState<PreparationSectionType>("operations");

    const settings = async () => {
        props.routing.replace("/settings");
    };

    const businessDocumentation = async () => {
        props.routing.replace("/business-documentation");
    };

    const logout = async () => {
        if (context.isImpersonatedUser) {
            context.clearImpersonation();
            window.location.replace(`${window.location.origin}/back-office/user`);
        } else {
            await authenticationService.logout();
            props.routing.replace("/login");
            context.reset();
        }
    };

    const setRouteExpanded = (route: PreparationSectionType) => {
        setDefaultRouteExpanded(route);
    };

    return <div className="pageView">
        <div className="operationMenu">
            <VPPreparationMenu defaultRouteExpanded={defaultRouteExpanded} routing={props.routing} />
        </div>
        <WarehouseContextProvider routing={props.routing}
                                  user={context.currentUser}>
            <div className={`page-paper ${context.isImpersonatedUser ? "page-paper--impersonated" : ""}`}>
                <div className={"pageHeader"}>
                    <Header routing={props.routing}>
                        <HeaderWidgetGroup>
                            <Breadcrumbs routing={props.routing} builder={new FrontendBreadcrumbsBuilder()} />
                        </HeaderWidgetGroup>
                        <HeaderWidgetGroup>
                            <LoaderIndicatorWidget />
                            <RemotePrintingContextProvider>
                                <MobileConnectorWidget />
                            </RemotePrintingContextProvider>
                            <SupportTicketWidget />
                            {context.isSupplier && context.hasPermission(Permission.FeaturePalletPickUpForm) &&
                                <PalletPickupFormWidget />}
                            <LanguageSelector locale={context.settings.lang} />
                            <UserAccountWidget name={context.currentUser.name}
                                               logoutText={t(context.isImpersonatedUser ? "navigation.backToBackoffice" : "navigation.logout")}
                                               onSettingsOpen={() => settings()}
                                               onBusinessDocumentationOpen={() => businessDocumentation()}
                                               onLogout={() => logout()} />
                        </HeaderWidgetGroup>
                    </Header>
                </div>
                <div className={"page-content__wrapper"}>
                    <Routes>
                        <Route path="operations/*" element={<OperationRouter routing={props.routing} setRouteExpanded={setRouteExpanded}/>} />
                        <Route path="pallet/*" element={<PalletRouter routing={props.routing} setRouteExpanded={setRouteExpanded} />} />
                        <Route path="/business-documentation" element={<BusinessDocumentationList />} />
                        <Route path="/barcode-scanner-test" element={<BarcodeScannerTestPage />} />
                        <Route path="/" element={<Navigate to={`/operations/${routeReporting}`} />} />
                    </Routes>
                </div>
            </div>
        </WarehouseContextProvider>
    </div>;
};
