import { httpPost } from "../../common/network/fetch";
import { ISupportTicket, SupportTicket } from "../model/support-ticket";

export interface ISupportTicketAttachmentData {
    fileName: string;
    content: string;
}

class SupportTicketService {
    public async create(warehouseCode: string, topic: string, team: string, technology: string, supplier: string, spokenLanguage: string, issue: string, severity: string,
                        description: string, contactName: string, contactPreferences: string, contactPhone: string, contactEmail: string,
                        attachments: ISupportTicketAttachmentData[]) {
        return new SupportTicket(await httpPost<ISupportTicket>({
            url: "/support-ticket/create",
            data: {
                warehouseId: warehouseCode, topic, team, technology, supplier, spokenLanguage, issue, severity, description, contactName, contactPreferences, contactPhone,
                contactEmail, attachments,
            },
        }, false));
    }
}

export const supportTicketService = new SupportTicketService();
