import * as React from "react";
import { CardContent, CardHeader, Card, Button } from "@mui/material";
import { IReactionDisposer, reaction } from "mobx";
import { getFieldLabel, stringListValue, stringValue } from "../../field/renderer";
import { FormModel } from "./form-model";
import { IDomain } from "../../dto/idomain";
import { Icon } from "../icon/icon";
import { Input, InputComponentType } from "../input/input";
import { clone } from "lodash";
import { dialogModel } from "../dialog/dialog-model";
import { getDomain } from "../../dto/dto-annotation";
import { mandatoryValidator } from "../../validators/mandatory-validator";
import { t } from "i18next";
import { ISelectItem } from "../input/iselect-item";
import { InputMultiple, InputMultipleComponentType } from "../input/input-multiple";
import { mandatoryMultipleValidator } from "../../validators/mandatory-multiple-validator";

import "./style.scss";

interface IFormBaseProps {
    className?: string;
}

export abstract class Form<TData extends {}, P = {}> extends React.Component<P & IFormBaseProps, {}> {

    private fieldMetadata: Map<keyof TData, { validators: IValidator[] }> =
        new Map<keyof TData, { validators: IValidator[] }>();

    protected formModel: FormModel<TData>;

    private autoload: IReactionDisposer;
    private initialData: TData;

    protected abstract getDataKey(): any;
    protected abstract load(dataKey?: any): Promise<TData>;
    protected abstract renderForm(data: TData): JSX.Element;
    protected abstract submitForm(data: TData): Promise<any>;
    protected abstract onSuccess(response: any): void;

    private cancelLabel: string;
    private submitLabel = "components.form.action.submit";
    private editLabel = "components.form.action.edit";
    private isCancelEnabled: boolean = true;
    private renderHeader: () => JSX.Element;
    private renderFooter: () => JSX.Element;
    private title?: string;
    private isActionInFooter?: boolean;
    private confirmMessage?: () => string;
    private validateForm: (data: TData, errorMap: Map<keyof TData, string[]>) => void;

    constructor(props: P & IFormBaseProps, defaultProps?: {
        isDefaultEdit?: boolean,
        cancelLabel?: string,
        submitLabel?: string,
        isCancelEnabled?: boolean,
        renderHeader?: () => JSX.Element,
        renderFooter?: () => JSX.Element,
        title?: string,
        isActionInFooter?: boolean,
        confirmMessage?: () => string,
        validateForm?: (data: TData, errorMap: Map<keyof TData, string[]>) => void,
    }) {
        super(props);
        if (defaultProps) {
            this.formModel = new FormModel<TData>(defaultProps && defaultProps.isDefaultEdit);
            this.title = defaultProps.title;
            this.isActionInFooter = defaultProps.isActionInFooter;
            this.confirmMessage = defaultProps.confirmMessage;
            this.validateForm = defaultProps.validateForm || (() => { return; });
            this.renderHeader = defaultProps.renderHeader ? defaultProps.renderHeader : this.renderUndefined;
            this.renderFooter = defaultProps.renderFooter ? defaultProps.renderFooter : this.renderUndefined;
            this.submitLabel = defaultProps.submitLabel ? defaultProps.submitLabel : "components.form.action.submit";

            if (defaultProps.isCancelEnabled === false) {
                this.isCancelEnabled = defaultProps.isCancelEnabled;
            }
            if (this.isCancelEnabled) {
                this.cancelLabel =
                    defaultProps.cancelLabel ? defaultProps.cancelLabel : "components.form.action.cancel";
            }
        }
    }

    public async componentWillMount() {
        this.autoload = reaction(
            () => this.getDataKey(),
            async dataKey => {
                if (dataKey) {
                    this.initData(await this.load(dataKey));
                }
            },
        );
        this.initData(await this.load(this.getDataKey()));
    }

    public componentWillUnmount() {
        if (this.autoload) {
            this.autoload();
        }
    }

    public initData(data: TData) {
        this.formModel.setData(data);
        this.initialData = clone(this.formModel.data);
    }

    public renderUndefined(): JSX.Element {
        return <></>;
    }

    public render() {
        this.fieldMetadata.clear();

        return <section className="form" id={this.title}>
            {this.renderHeader()}
            <Card className={"container" + (this.props.className ? ` ${this.props.className}` : "")}>
                <form onSubmit={async event => await this.submit(event)} >
                    {this.title &&
                        <CardHeader title={t(this.title)} className="form-header"
                            action={!this.isActionInFooter && this.renderActions()} />}
                    <CardContent >
                        {this.renderForm(this.formModel.data)}
                        {this.isActionInFooter && this.renderActions()}
                    </CardContent >
                </form>
            </Card>
            {this.renderFooter()}
        </section>;
    }

    protected renderActions() {
        if (this.formModel.isEdit) {
            return this.renderEditAction();
        }
        return this.renderConsultAction();
    }
    private renderEditAction() {
        if (!this.isCancelEnabled) {
            return <div className="buttonContainer">
                {this.renderSubmitButton()}
            </div>;
        }
        return <div className="buttonContainer">
            {this.renderCancelButtonButton()}
            {this.renderSubmitButton()}
        </div>;
    }

    private renderConsultAction() {
        return <div className="buttonContainer">
            {this.renderCustomConsultAction()}
            {this.renderEditButton()}
        </div>;
    }

    private renderCancelButtonButton() {
        if (this.isActionInFooter) {
            return <Button color={"inherit"} variant="contained" onClick={() => this.cancel()} key="cancel-btn">{t(this.cancelLabel)}</Button>;
        }
        return <Icon icon="reply" onClick={() => this.cancel()} key="cancel-btn" />;
    }

    protected renderSubmitButton() {
        if (this.isActionInFooter) {
            return <Button color="primary" variant="contained" type="submit" autoFocus key="submit-btn">
                {t(this.submitLabel)}</Button>;
        }
        return <Icon icon="save" type="submit" autoFocus key="submit-btn" />;
    }

    private renderEditButton() {
        if (this.isActionInFooter) {
            return <Button variant="contained" onClick={() => this.formModel.toggleEdit()} key="edit-btn" disabled={this.formModel.isReadOnly}>
                {t(this.editLabel)}</Button>;
        }
        return <Icon icon="mode_edit" onClick={() => this.formModel.toggleEdit()} key="edit-btn" disabled={this.formModel.isReadOnly}/>;
    }

    private cancel() {
        this.formModel.resetForm(clone(this.initialData));
        this.onCancel();
    }

    protected onCancel() {
        // Method to overide if necessary.
    }

    protected resetForm() {
        this.formModel.resetForm(clone(this.initialData));
    }

    protected renderCustomConsultAction() {
        return <></>;
    }

    private async submit(event: any) {
        event.preventDefault();
        const errorMap = new Map<keyof TData, string[]>();
        this.fieldMetadata.forEach((metadata, key) => {
            const error = this.validateField(this.formModel.data[key as keyof TData], metadata.validators);
            if (error) {
                errorMap.set(key, error);
            }
        });

        this.validateForm(this.formModel.data, errorMap);

        this.formModel.resetErrors(errorMap);
        if (errorMap.size === 0) {
            if (this.confirmMessage) {
                dialogModel.confirmBox(
                    t("components.form.action.submitConfirmTitle"),
                    this.confirmMessage(),
                    async () => await this.save(),
                );
            } else {
                await this.save();
            }
        }
    }

    private async save() {
        const response = await this.submitForm(this.formModel.data);
        this.initData(this.formModel.data);
        this.formModel.toggleEdit();
        this.onSuccess(response);
    }

    // FIELDS METHODS
    protected getField<K extends keyof TData>(target: TData, propertyKey: K, props: {
        isRequired?: boolean,
        isDisableLabel?: boolean,
        label?: string,
        onChange?: (newValue: any) => void,
        disabled?: boolean,
        options?: Array<ISelectItem<string>>,
        inputComponent?: InputComponentType;
        addEmptyOption?: boolean;
    } = { isRequired: false }) {

        const domain = getDomain(target, propertyKey);
        // Register validator.
        this.fieldMetadata.set(propertyKey, {
            validators: this.getFieldValidators(domain, props.isRequired),
        });

        return React.createElement(Input, {
            disabled: props.disabled || !this.formModel.isEdit,
            errors: this.formModel.getFieldErrors(propertyKey),
            isRequired: props.isRequired,
            label: props.isDisableLabel ? undefined : (props.label || getFieldLabel(target, propertyKey)),
            onChange: (newValue: any) => this.onChange(propertyKey, newValue, props.onChange),
            propertyKey: propertyKey as string,
            htmlType: domain ? domain.htmlType : undefined,
            value: stringValue(target, propertyKey),
            options: props.addEmptyOption === true && props.options !== undefined ? [...[{value: "", display: ""}], ...props.options!] : props.options,
            inputComponent: props.inputComponent || (domain ? domain.inputComponent as InputComponentType : "textField"),
        });
    }

    // FIELDS METHODS
    protected getFieldMultiple<K extends keyof TData>(target: TData, propertyKey: K, props: {
        options?: Array<ISelectItem<string>>,
        isRequired?: boolean,
        isDisableLabel?: boolean,
        label?: string,
        onChange?: (newValue: any) => void,
        disabled?: boolean,
        inputComponent?: InputMultipleComponentType,
    } = { options: [], isRequired: false }) {

        const domain = getDomain(target, propertyKey);
        // Register validator.
        this.fieldMetadata.set(propertyKey, {
            validators: this.getMultipleFieldValidators(domain, props.isRequired),
        });

        return React.createElement(InputMultiple, {
            disabled: props.disabled || !this.formModel.isEdit,
            errors: this.formModel.getFieldErrors(propertyKey),
            isRequired: props.isRequired,
            label: props.isDisableLabel ? undefined : (props.label || getFieldLabel(target, propertyKey)),
            onChange: (newValue: string[]) => this.onChange(propertyKey, newValue, props.onChange),
            propertyKey: propertyKey as string,
            value: stringListValue(target, propertyKey),
            options: props.options,
            inputComponent: props.inputComponent || (domain ? domain.inputComponent as InputMultipleComponentType : "checkboxList"),
        });
    }

    private getFieldValidators(domain: IDomain<any>, isRequired?: boolean) {
        const validators = domain ? clone(domain.validators) : [];
        if (isRequired) {
            validators.push(mandatoryValidator);
        }
        return validators;
    }

    private getMultipleFieldValidators(domain: IDomain<any>, isRequired?: boolean) {
        const validators = domain ? clone(domain.validators) : [];
        if (isRequired) {
            validators.push(mandatoryMultipleValidator);
        }
        return validators;
    }

    private onChange<K extends keyof TData>(propertyKey: K, newValue: any, onChange?: (newValue: any) => void) {
        const domain = getDomain(this.formModel.data, propertyKey);
        this.formModel.setFormValue(propertyKey, domain ? domain.unformat(newValue) : newValue);
        if (onChange) {
            onChange(newValue);
        }
    }

    private validateField(value: any | undefined, validators: IValidator[]) {
        const errors: string[] = [];
        validators.forEach(validator => {
            const error = validator.validate(value);
            if (error) {
                errors.push(error);
            }
        });

        if (errors.length > 0) {
            return errors;
        }
        return;
    }
}
