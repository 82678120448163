import { httpGetList, httpPut, httpGet } from "../../common/network/fetch";
import { Guid } from "guid-typescript";
import { IRemotePrintDocumentResponse, RemotePrintDocument } from "../model/remote-print-document";

export class RemotePrinting {
    public async list(printerId: Guid) {
        return (await httpGetList<string>({
            url: `/remote-printing/list/active/${printerId}`,
        })).map(i => Guid.parse(i));
    }

    public async remotePrintsForUser() {
        return RemotePrintDocument.fromResponse(await httpGet<IRemotePrintDocumentResponse>({
            url: `/remote-printing/list/active`,
        }));
    }

    public async confirmPrintout(documentId: Guid) {
        return httpPut({
            url: `/remote-printing/complete/${documentId}`,
        });
    }
}

export const remotePrintingService = new RemotePrinting();
