import * as React from "react";

import { inject, observer } from "mobx-react";
import { IHistoryProps } from "../../../../common/navigation/ihistory-props";
import { SearchListModel } from "../../../../common/component/list/search-list/search-list-model";
import { context } from "../../../../context/context";
import { getLabelFormat } from "../../../../constants/constants";
import { Permission } from "../../../../context/permission";
import { filter } from "lodash";
import { PalletShippingHeader } from "./pallet-shipping-header";
import { IActionCommand } from "../../../../common/component/action/action-select/bulky-action-picker";
import { ManagePalletActionType } from "../../../constants/constants";
import { t } from "i18next";
import { palletService } from "../../../services/pallet";
import { notificationModel } from "../../../../common/component/notifications/notification-model";
import moment from "moment";
import { PalletShippingDialog } from "../pallet-shipping-dialog/pallet-shipping-dialog";
import { SingleActionPicker } from "../../../../common/component/action/action-select/single-action-picker";
import { qzManager } from "../../../qz/qz-manager";
import { PalletsList } from "../pallets-list/pallets-list";
import { PalletsListItem } from "../pallets-list/pallets-list-item";
import { PalletsListSubItem } from "../pallets-list/pallets-list-sub-item";
import { PalletFilter } from "../pallets-list/pallet-filter";
import { PalletDataProvider } from "../pallets-list/pallet-data-provider";
import { mixedPalletTypeParamActionValidator } from "../pallet-shipping-dialog/mixed-pallet-type-param-action-validator";

const parcelManagementModel = new SearchListModel<PalletsListItem, PalletsListSubItem, PalletFilter>();

interface IPalletShippingState {
    isPalletShippingDialogOpen: boolean;
    palletShippingDialogPallets: PalletsListItem[];

    singleActionPickerAnchorElement: HTMLElement | null;
    singleActionPickerPalletItem: PalletsListItem | null;
}

@inject("routing")
@observer
export class PalletShipping extends PalletsList<IPalletShippingState> {
    public state: IPalletShippingState = {
        isPalletShippingDialogOpen: false,
        palletShippingDialogPallets: [],

        singleActionPickerAnchorElement: null,
        singleActionPickerPalletItem: null,
    };

    constructor(props: IHistoryProps) {
        super(props, parcelManagementModel, new PalletDataProvider("Closed"));
    }

    protected renderActions() {
        return <>
            <PalletShippingHeader actionsList={this.actionsList}
                                  reloadPalletList={() => this.reloadPalletList()} />
            {this.state.isPalletShippingDialogOpen &&
                <PalletShippingDialog palletList={this.state.palletShippingDialogPallets}
                                      getPalletItemByScan={palletCode => this.getPalletItemByScan(palletCode)}
                                      onSubmit={((identifierList, truckId, pickingDate, sealNumber) =>
                                          this.shipPallet(identifierList, truckId, pickingDate, sealNumber))}
                                      onCancel={() => this.closePalletShipping()} />}
            {this.state.singleActionPickerAnchorElement &&
                <SingleActionPicker anchorElement={this.state.singleActionPickerAnchorElement}
                                    item={this.state.singleActionPickerPalletItem}
                                    list={this.singleActionsList}
                                    onActionComplete={() => this.reloadPalletList()}
                                    onPickerClose={() => this.closeSingleActionPickerMenu()} />}
            </>;
    }

    protected get actionsList(): Array<IActionCommand<PalletsListItem, ManagePalletActionType>> {
        return [
            {
                label: t("palletShipping.action.ship.title"),
                value: "ship" as ManagePalletActionType,
                loadExecuteParam: (isScan, param) => this.loadExecuteParam(isScan, param),
                execute: async identifierList => this.openPalletShippingForSelection(identifierList),
                executeCustomScanMode: () => this.openPalletShippingForScan(),
                hideExecutionConfirmation: true,
                paramValidator: mixedPalletTypeParamActionValidator,
            }, {
                label: t("palletShipping.action.printLabel.title"),
                value: "printLabel" as ManagePalletActionType,
                loadExecuteParam: (isScan, param) => this.loadExecuteParam(isScan, param),
                execute: async identifierList => await this.printLabel(identifierList),
            }, {
                label: t("palletShipping.action.printDeliverySlip.title"),
                value: "printDeliverySlip" as ManagePalletActionType,
                loadExecuteParam: (isScan, param) => this.loadExecuteParam(isScan, param),
                execute: async identifierList => await this.printDeliverySlip(identifierList),
            }, {
                label: t("palletShipping.action.reopen.title"),
                value: "reopen" as ManagePalletActionType,
                loadExecuteParam: (isScan, param) => this.loadExecuteParam(isScan, param),
                execute: async identifierList => await this.reopenPallet(identifierList),
            },
        ];
    }

    private async openPalletShippingForSelection(identifierList: PalletsListItem[]) {
        this.setState({
            isPalletShippingDialogOpen: true,
            palletShippingDialogPallets: identifierList,
        });
    }

    private async openPalletShippingForScan() {
        this.setState({
            isPalletShippingDialogOpen: true,
            palletShippingDialogPallets: [],
        });
    }

    private closePalletShipping() {
        this.setState({
            isPalletShippingDialogOpen: false,
            palletShippingDialogPallets: [],
        });
    }

    protected openSingleActionPickerMenu(anchor: any, item: PalletsListItem) {
        this.setState({
            singleActionPickerAnchorElement: anchor,
            singleActionPickerPalletItem: item,
        });
    }

    private closeSingleActionPickerMenu() {
        this.setState({
            singleActionPickerPalletItem: null,
            singleActionPickerAnchorElement: null,
        });
    }

    private getPalletItemByScan(scanValue: string) {
        const result = this.getPalletListByScan(scanValue);

        return result.length > 0 ? result[0] : undefined;
    }

    private async printLabel(identifierList: PalletsListItem[]) {
        for (const p of identifierList) {
            const data = await palletService.label(p.id, getLabelFormat(context.settings.printerType));
            await qzManager.qzPrintOrDownloadFile(data.Value!, context.settings.printerType);
        }

        await this.reloadPalletList();
    }

    private async printDeliverySlip(identifierList: PalletsListItem[]) {
        const document = await palletService.deliverySlip(identifierList.map(p => p.id));
        await qzManager.qzPrintOrDownloadFile(document.file!, context.settings.printerType);
        await this.reloadPalletList();
    }

    private async reopenPallet(list: PalletsListItem[]) {
        let nbFailed = 0;
        for (const p of list) {
            try {
                await palletService.reopenPallet(p.id);
            } catch (_err) {
                nbFailed++;
            }
        }

        await this.reloadPalletList();
        if (list.length !== nbFailed) {
            notificationModel.addSuccessMessage(this.getSuccessMessage("reopen", list.length - nbFailed));
        } else {
            notificationModel.addErrorMessage(t(`palletShipping.action.reopen.noItems`));
        }
    }

    private async shipPallet(list: PalletsListItem[], truckId: string, pickingDate: moment.Moment, sealNumber: string) {
        await palletService.shipPallets(list.map(p => p.palletId), truckId, pickingDate, sealNumber);

        if (list.length > 1) {
            notificationModel.addSuccessMessage(t("components.pallet.shipping.successMultiple", {
                count: list.length,
            }));
        } else {
            notificationModel.addSuccessMessage(t("components.pallet.shipping.success", {
                palletCode: list[0].code,
            }));
        }

        this.closePalletShipping();
        await this.reloadPalletList();
    }

    private loadExecuteParam(isScan: boolean, param: string) {
        return isScan ?
            this.getPalletListByScan(param) :
            this.searchListModel.selectedItemsList;
    }

    private getSuccessMessage(action: string, nbProcessed: number) {
        return t(`palletShipping.action.${action}.success` + (nbProcessed > 1 ? "Multiple" : ""),
            { count: nbProcessed });
    }

    protected get isSelectionEnabled() {
        return context.hasPermission(Permission.FrontWrite);
    }

    protected getPalletListByScan(scannedValue: string): PalletsListItem[] {
        return filter(this.searchListModel.list, pallet => pallet.code.toString() === scannedValue);
    }
}
