import { CreatePalletRequestCarrierCountryItem } from "./create-pallet-request-carrier-country-item";
import { PalletType } from "../../../constants/constants";

export class CreatePalletCommand {
    private readonly _warehouse: string;
    private readonly _carrierCountryGroup: CreatePalletRequestCarrierCountryItem[];
    private readonly _maxParcelWeightValueKgs: number;
    private readonly _maxTotalWeightValueKgs: number;
    private readonly _creationComment: string;
    private readonly _printPalletLabel: boolean;
    private readonly _palletType: PalletType;

    constructor(warehouse: string, carrierCountryGroup: CreatePalletRequestCarrierCountryItem[],
                maxParcelWeightValueKgs: number, maxTotalWeightValueKgs: number, creationComment: string,
                printPalletLabel: boolean, isAdjustmentPallet: boolean) {
        this._warehouse = warehouse;
        this._carrierCountryGroup = carrierCountryGroup;
        this._maxParcelWeightValueKgs = maxParcelWeightValueKgs;
        this._maxTotalWeightValueKgs = maxTotalWeightValueKgs;
        this._creationComment = creationComment;
        this._printPalletLabel = printPalletLabel;
        this._palletType = isAdjustmentPallet ? "Adjustment" : "Regular";
    }

    public get warehouse(): string {
        return this._warehouse;
    }

    public get carrierCountryGroup() {
        return this._carrierCountryGroup;
    }

    public get maxParcelWeightValueKgs() {
        return this._maxParcelWeightValueKgs;
    }

    public get maxTotalWeightValueKgs() {
        return this._maxTotalWeightValueKgs;
    }

    public get creationComment() {
        return this._creationComment;
    }

    get printPalletLabel(): boolean {
        return this._printPalletLabel;
    }

    public get palletType(): PalletType {
        return this._palletType;
    }
}
