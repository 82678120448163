import "reflect-metadata";

import { IDomain } from "./idomain";
import { FilterActionType } from "../component/list/filter/filter-action-data";

const domainMetadataKey = Symbol("domain");
const labelMetadataKey = Symbol("label");
const filterOperatorsMetadataKey = Symbol("filterSettings");

export function domain(domainValue: IDomain<any>) {
    return Reflect.metadata(domainMetadataKey, domainValue);
}

export function getDomain<T extends {}, K extends keyof T>(target: T, propertyKey: K): IDomain<any> {
    return Reflect.getMetadata(domainMetadataKey, target, propertyKey as string);
}

export function label(labelKey: string) {
    return Reflect.metadata(labelMetadataKey, labelKey);
}

export function getLabel<T extends {}, K extends keyof T>(target: T, propertyKey: K): string {
    return Reflect.getMetadata(labelMetadataKey, target, propertyKey as string);
}

interface IFilterMetadataType {
    actions?: FilterActionType[];
    options?: string[];
}

export function filterSettings(operators: IFilterMetadataType) {
    return Reflect.metadata(filterOperatorsMetadataKey, operators);
}

export function getFilterSettings<T extends {}, K extends keyof T>(target: T, propertyKey: K): IFilterMetadataType {
    return Reflect.getMetadata(filterOperatorsMetadataKey, target, propertyKey as string);
}
