import "./style.scss";

import * as React from "react";

import { FilterActionData, FilterActionType, filterActionAllValues } from "./filter-action-data";

import { FilterData } from "./filter-data";
import { Form } from "../../form/form";
import { FormFilterModel } from "./form-filter-model";
import { Input, Select } from "@mui/material";
import { SearchListModel } from "../search-list/search-list-model";
import { clone } from "lodash";
import { getFieldLabel } from "../../../field/renderer";
import { getFilterSettings } from "../../../dto/dto-annotation";
import { t } from "i18next";
import { ISelectItem } from "../../input/iselect-item";
import { ISearchListSubRow, ISearchListRow } from "../search-list/search-list";
import { extendObservable } from "mobx";

interface IFormFilter<TFilter extends {}, TRow extends ISearchListRow<TSubRow>, TSubRow extends ISearchListSubRow> {
    initFilter: () => TFilter;
    defaultFilter: FilterData<TFilter, TRow, TSubRow>;
    searchListModel: SearchListModel<TRow, TSubRow, TFilter>;
}

export abstract class FormFilter<TFilter extends {}, TRow extends ISearchListRow<TSubRow>, TSubRow extends ISearchListSubRow, P = {}>
    extends Form<TFilter, P & IFormFilter<TFilter, TRow, TSubRow>> {

    private formFilterModel = new FormFilterModel<TFilter, TRow, TSubRow>();

    constructor(props: P & IFormFilter<TFilter, TRow, TSubRow>) {
        super(props, {
            isDefaultEdit: true,
            isActionInFooter: true,
            cancelLabel: "filter.action.reset",
            submitLabel: "filter.action.filter",
        });
    }

    public initData(data: TFilter) {
        const additionalFields: any = {};
        this.props.searchListModel.additionalFields.forEach(field => {
            additionalFields[field] = (data as any)[field] || "";
        });
        extendObservable(data, additionalFields);
        super.initData(data);
    }

    protected onCancel() {
        this.formFilterModel.resetFilterActionMap([]);
        this.formModel.setData(this.props.initFilter());
        this.formModel.toggleEdit(); // Not very good
    }

    protected getFieldSubFilter<K extends keyof TRow, S extends keyof TFilter & keyof TSubRow>(
        target: TFilter,
        filterKey: S,
        rowKey: K,
        label?: string,
        filterValueOptions?: Array<ISelectItem<string>>,
    ) {
        const currentAction = this.formFilterModel.getFilterAction(filterKey);
        return <div data-testid={`filter-field-${filterKey.toString()}`}>
            {this.renderLabel(target, filterKey, label)}
            {this.renderSelectAction(
                target,
                filterKey,
                (event: any) =>
                    this.handleSubFilterChange(filterKey, event, rowKey),
                currentAction)}
            {this.renderInput(target, filterKey, currentAction, filterValueOptions)}
        </div>;
    }

    protected getFieldFilter<K extends keyof TFilter & keyof TRow>(
        target: TFilter,
        filterKey: K,
        filterValueOptions?: Array<ISelectItem<string>>) {
        const currentAction = this.formFilterModel.getFilterAction(filterKey);

        return <div data-testid={`filter-field-${filterKey.toString()}`}>
            {this.renderLabel(target, filterKey)}
            {this.renderSelectAction(
                target,
                filterKey,
                (event: any) => this.handleFilterChange(filterKey, event),
                currentAction)}
            {this.renderInput(target, filterKey, currentAction, filterValueOptions)}
        </div>;
    }

    protected switchOnFilterAction<K extends keyof TFilter & keyof TRow>(filterKey: K) {
        this.formFilterModel.setFormFilterValue(filterKey,
            new FilterActionData<TFilter, TRow, TSubRow>(filterKey, "isTrue"));
    }

    protected switchOffFilterAction<K extends keyof TFilter & keyof TRow>(filterKey: K) {
        this.formFilterModel.setFormFilterValue(filterKey,
            new FilterActionData<TFilter, TRow, TSubRow>(filterKey, "all"));
    }

    protected renderAdditionalDetailFilterFieds<K extends keyof TRow>(target: TFilter, rowKey: K) {
        return this.props.searchListModel.additionalFields.map(field => this.getFieldSubFilter(target, field as any, rowKey, field));
    }

    private renderSelectAction<K extends keyof TFilter>(
        target: TFilter,
        filterKey: K,
        onChange: (event: any) => void,
        currentAction?: FilterActionData<TFilter, TRow, TSubRow>) {

        const filterOperator = getFilterSettings(target, filterKey);

        const filterActions = filterOperator && filterOperator.actions ?
            filterOperator.actions : filterActionAllValues;

        return <Select
            native
            onChange={(event: any) => onChange(event)}
            input={<Input />}
            value={currentAction ? currentAction.filterAction : ""}
            data-testid="operator"
            className="operator"
        >
            {filterActions.map(filterAction => this.renderOption(filterAction))}
        </Select>;
    }

    private renderInput<K extends keyof TFilter & (keyof TRow | keyof TSubRow)>(
        target: TFilter,
        filterKey: K,
        currentAction?: FilterActionData<TFilter, TRow, TSubRow>,
        options?: Array<ISelectItem<string>>) {
        const disabled = !currentAction || this.isNoInputFilterAction(currentAction!.filterAction);

        return this.getField(target, filterKey, {
            isDisableLabel: true,
            disabled,
            isRequired: !disabled,
            options,
            inputComponent: options ? "select" : "textField",
        });
    }

    private isNoInputFilterAction(filterAction: FilterActionType) {
        return filterAction === "all"
            || filterAction === "empty"
            || filterAction === "notEmpty"
            || filterAction === "isTrue"
            || filterAction === "isFalse";
    }

    private renderLabel<K extends keyof TFilter>(target: TFilter, filterKey: K, label?: string) {
        return <div>{label || getFieldLabel(target, filterKey)}</div>;
    }

    private renderOption(filterAction: FilterActionType) {
        return <option value={filterAction} key={filterAction} data-testid={`op-${filterAction}`}>
            {t(`filter.action.${filterAction}`)}
        </option>;
    }

    protected getDataKey() {
        return undefined;
    }

    protected async load(_dataKey: any) {
        if (!this.props.defaultFilter.isAndSearch) {
            return this.props.initFilter();
        }
        const defaultFilter = this.props.defaultFilter;
        this.formFilterModel.resetFilterActionMap(defaultFilter.filterAction);
        // Clone is important to avoid list refresh when data is changed in form.
        return clone(defaultFilter.filter);
    }

    private handleFilterChange(filterKey: keyof TFilter & keyof TRow, event: any) {
        const action = event.target.value;
        if (this.isNoInputFilterAction(action)) {
            this.formModel.setFormValue(filterKey, "");
        }
        this.formFilterModel.setFormFilterValue(filterKey,
            new FilterActionData<TFilter, TRow, TSubRow>(filterKey, action));
    }

    private handleSubFilterChange(
        filterKey: keyof TFilter & keyof TSubRow,
        event: any,
        rowKey?: keyof TRow,
    ) {
        const action = event.target.value;
        if (this.isNoInputFilterAction(action)) {
            this.formModel.setFormValue(filterKey, "");
        }
        this.formFilterModel.setFormFilterValue(filterKey,
            new FilterActionData<TFilter, TRow, TSubRow>(filterKey, action, rowKey));
    }

    protected async submitForm(data: TFilter) {
        this.props.searchListModel.applyFilter(new FilterData(data, true, this.formFilterModel.filterActionList));
    }

    protected onSuccess(_reponse: any) {
        // Nothing to do.
    }
}
