import "babel-polyfill";

import * as React from "react";
import * as ReactDOM from "react-dom";

import { AppRouter } from "./components/app-router";
import { LocaleType } from "../constants/constants";
import { context } from "../context/context";
import { i18nManagerFrontOffice } from "./i18n/i18n-manager-front-office";
import { useStrict } from "mobx";

export const AppContainerId = "app-content";

class AppInitializer {

  public async initAsync() {
    useStrict(true);

    document.addEventListener("DOMContentLoaded", async () => await this.firstRenderApp());
  }

  private async firstRenderApp() {
    await i18nManagerFrontOffice.init(this.renderApp);
    context.applicationName = "Front-Office";
    context.initUserSession();
    await context.initAuthenticatedContextAsync();
    await this.renderApp();
  }

  private renderApp(newLocale?: LocaleType) {
    ReactDOM.render(
      React.createElement(AppRouter, {
        locale: newLocale,
      }),
      document.getElementById(AppContainerId));
  }
}

const initializer = new AppInitializer();
initializer.initAsync();
