import { PalletsListItem } from "../pallets-list/pallets-list-item";
import { Button, DialogActions, DialogContent, FormControl, FormLabel, TextField, Typography } from "@mui/material";
import { t } from "i18next";
import React, { useState } from "react";
import { SquaredChip } from "../../../../common/component/squared-chip/squared-chip";
import { blue } from "@mui/material/colors";
import { DangerousGoods } from "../../../../constants/constants";
import { PalletCloseDialogAdr } from "./close-pallet-dialog-adr";

interface IPalletCloseDialogCommentProps {
    closingPalletsList: PalletsListItem[];

    onSubmit: (comment: string) => Promise<void>;
    onClose: () => void;
}

export const PalletCloseDialogComment = (props: IPalletCloseDialogCommentProps) => {
    const [comment, setComment] = useState("");

    const palletsWithAdrProducts = props.closingPalletsList.filter(p => p.dangerousGoods !== DangerousGoods.None);

    return <>
        <DialogContent>
            <div className={"pallet-closing-details-summary"}>
                <Typography>
                    {t("components.pallet.closing.details.description", {
                        count: props.closingPalletsList.length,
                    })}
                </Typography>
                <div className={"pallet-closing-details-summary__list"}>
                    {props.closingPalletsList.map(c => <SquaredChip propertyKey={`item-${c.code}`} color={blue} label={c.code} />)}
                </div>
                {palletsWithAdrProducts.length !== 0 && <PalletCloseDialogAdr adrPalletsList={palletsWithAdrProducts}/>}
            </div>
        </DialogContent>
        <DialogContent>
            <div className={"pallet-closing-form"}>
                <div className={"pallet-closing-form__container"}>
                    <FormControl variant={"outlined"} fullWidth>
                        <FormLabel htmlFor={"closing-comment"}>
                            {t("components.pallet.closing.comment")}
                        </FormLabel>
                        <TextField id={"closing-comment"}
                                   variant={"outlined"}
                                   data-testid={"closing-comment"}
                                   multiline
                                   rows={2}
                                   maxRows={4}
                                   inputProps={{ maxLength: 255 }}
                                   onChange={event => setComment(event.target.value)}
                                   value={comment} />
                    </FormControl>
                </div>
            </div>
        </DialogContent>
        <DialogActions>
            <Button color={"inherit"}
                    variant={"contained"}
                    type={"submit"}
                    key={"cancel-btn"}
                    data-testid={"pallet-close-cancel"}
                    onClick={() => props.onClose()}>
                {t("components.popin.cancel")}
            </Button>
            <Button color={"secondary"}
                    variant={"contained"}
                    type={"submit"}
                    key={"submit-btn"}
                    data-testid={"pallet-close-submit"}
                    onClick={async () => await props.onSubmit(comment)}>
                {(t("components.pallet.closing.close"))}
            </Button>
        </DialogActions>
    </>;
};
