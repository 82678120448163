import { Parcel } from "../../../../model/parcel";
import moment from "moment";

class CustomFilenameProvider {
    public composeFilename(filenameFormat: string, parcel: Parcel) {
        filenameFormat = filenameFormat.replace("%VP_OPERATION_CODE%", parcel.operationCode);
        filenameFormat = filenameFormat.replace("%VP_BATCH_ID%", parcel.batchId.toString());
        filenameFormat = filenameFormat.replace("%VP_ORDER_ID%", parcel.orderId.toString());
        filenameFormat = filenameFormat.replace("%VP_DELIVERY_ORDER_ID%", parcel.deliveryOrderId.toString());
        filenameFormat = filenameFormat.replace("%VP_PARCEL_ID%", parcel.parcelId.toString());
        filenameFormat = filenameFormat.replace("%TIMESTAMP%", moment().format("YYYYMMDDHHmmss"));

        const indexOfLastDot = filenameFormat.lastIndexOf(".");
        if (indexOfLastDot !== -1) {
            filenameFormat = filenameFormat.slice(0, indexOfLastDot);
        }

        return filenameFormat;
    }
}

export const customFilenameProvider = new CustomFilenameProvider();
