import { BulkParcelDataExporter } from "./export/bulk-parcel-data-exporter";
import { operationsService } from "../../../services/operations";
import { loaderModel } from "../../../../common/component/loader/loader-model";
import { BulkParcelUploadImporter } from "./import/bulk-parcel-upload-importer";
import { BulkParcelUploadDataReader } from "./import/bulk-parcel-upload-data-reader";
import { notificationModel } from "../../../../common/component/notifications/notification-model";
import { action, computed, observable } from "mobx";
import { menuOperationModel } from "../../preparation-menu/preparation-menu-model";
import { t } from "i18next";
import { DeliveryOrder } from "../../../model/delivery-order";
import { ParcelExportStrategy } from "./export/parcel-export-strategy";
import { ProductExportStrategy } from "./export/product-export-strategy";
import { BulkParcelUploadImportLine } from "../../../model/bulk-parcel-upload-import-line";
import { excelDataExporter } from "../../../../common/component/list/import-export-list/excel-data-exporter";
import { ExcelBackgroundColorRowDecorator } from "../../../../common/component/list/import-export-list/excel-background-color-row-decorator";
import { IExportDataFormat } from "./export/data-format/export-data-format";
import { context } from "../../../../context/context";
import { Permission } from "../../../../context/permission";
import { ShippingAddressExportDataFormat } from "./export/data-format/shipping-address-export-format";
import { DefaultDataExportDataFormat } from "./export/data-format/default-export-data-format";
import { Operation } from "../../../model/operation";
import { palletService } from "../../../services/pallet";
import { dialogModel } from "../../../../common/component/dialog/dialog-model";

class BulkParcelUploadModel {

    public readonly uploadFileAllowedTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];

    @observable
    private _importer: BulkParcelUploadImporter = new BulkParcelUploadImporter();

    @observable
    private _isExportDialogOpen: boolean = false;

    @observable
    private _parcelExportStrategy: ParcelExportStrategy = ParcelExportStrategy.ParcelPerOrder;

    @observable
    private _productExportStrategy: ProductExportStrategy = ProductExportStrategy.AllPerLine;

    @observable
    private _operation: Operation | undefined;

    @observable
    private _batchId: number | undefined;

    @observable
    private _exportOperation: boolean = true;

    @observable
    private _skipShippedOrders: boolean = false;

    @computed
    public get parcelExportStrategy(): string {
        return this._parcelExportStrategy.toString();
    }

    @action
    public setParcelExportStrategy(strStrategy: string) {
        this._parcelExportStrategy = ParcelExportStrategy.fromString(strStrategy);
        if (this.parcelExportStrategyIs(ParcelExportStrategy.ParcelPerProduct)) {
            this._productExportStrategy = ProductExportStrategy.SinglePerLine;
        }
    }

    @computed
    public get productExportStrategy(): string {
        return this._productExportStrategy.toString();
    }

    @action
    public setProductExportStrategy(strStrategy: string) {
        this._productExportStrategy = ProductExportStrategy.fromString(strStrategy);
    }

    @action
    public setExportOperation(exportOperation: boolean) {
        this._exportOperation = exportOperation;
    }

    @action
    public setSkipShippedOrders(skipShippedOrders: boolean) {
        this._skipShippedOrders = skipShippedOrders;
    }

    @computed
    public get importer() {
        return this._importer;
    }

    @computed
    public get isExportDialogOpen() {
        return this._isExportDialogOpen;
    }

    @computed
    public get exportOperation() {
        return this._exportOperation;
    }

    @computed
    public get skipShippedOrders() {
        return this._skipShippedOrders;
    }

    @action
    public openExportDialog(): void {
        this._isExportDialogOpen = true;
    }

    @action
    public closeExportDialog(): void {
        this._isExportDialogOpen = false;
    }

    @action
    public async cancelUpload(): Promise<void> {
        await this._importer.cancel();
    }

    @action
    public async cancelUploadAndClose(): Promise<void> {
        await this._importer.cancel();
        this._importer.reset();
    }

    @action
    public async exportBatch() {
        if (this._operation === undefined) {
            throw new Error("Error: Operation not selected");
        }

        const exporter = new BulkParcelDataExporter(this._operation.code,
            !this._exportOperation && this._batchId ? this._batchId : undefined,
            this._parcelExportStrategy, this._productExportStrategy, this.exportDataFormat, this._skipShippedOrders);

        const actionId = loaderModel.foregroundActionStart(t("bulkParcelUpload.export.loaderDescription"));

        try {
            const batchData = await this.getDeliveryOrders(this._operation,
                !this._exportOperation && this._batchId ? this._batchId : undefined);
            const warehouseOpenPalletList = context.hasPermission(Permission.FeaturePalletWorkflow) ?
                await palletService.loadPalletsWithExternalId(this._operation.warehouseId, "Open") : [];
            const carrierCountryGroupsList = context.hasPermission(Permission.FeaturePalletWorkflow) ?
                await palletService.loadCarrierCountryList(this._operation.warehouseId) : undefined;

            const exportData = exporter.getExportData(batchData, warehouseOpenPalletList, carrierCountryGroupsList);
            excelDataExporter.export(exportData, [
                ExcelBackgroundColorRowDecorator.forHeaderColumn(exportData.headerColumns, t("model.parcelExport.deliveryOrderId")),
            ]);
        } finally {
            loaderModel.foregroundActionEnd(actionId);
        }
    }

    public async uploadFile(file: File | undefined) {
        if (this._operation === undefined) {
            throw new Error("Error: Operation not selected");
        }

        if (file === undefined) {
            notificationModel.addErrorMessage(t("bulkParcelUpload.error.fileFormatInvalid"));
            return;
        }

        const importDataLines = await this.parseUploadedFileIntoUploadLines(file);
        if (!importDataLines) {
            return;
        }

        if (importDataLines.length === 0) {
            notificationModel.addErrorMessage(t("bulkParcelUpload.error.importMessage", {
                message: t("bulkParcelUpload.error.emptyFile"),
            }));
            return;
        }

        const isImportBatchScoped = importDataLines.find(line => line.batchId !== importDataLines[0].batchId) === undefined;
        if (!isImportBatchScoped && this._batchId !== undefined) {
            dialogModel.confirmBox(t("bulkParcelUpload.menu.importButton"), t("bulkParcelUpload.import.warning.crossBatchImport"), async () => {
                await this.processImport(importDataLines, await this.getDeliveryOrders(this._operation!));
            });

            return;
        }

        if (isImportBatchScoped && this._batchId === undefined) {
            await this.processImport(importDataLines, await this.getDeliveryOrders(this._operation, importDataLines[0].batchId));

            return;
        }

        await this.processImport(importDataLines, await this.getDeliveryOrders(this._operation, this._batchId));
    }

    private async parseUploadedFileIntoUploadLines(file: File) {
        if (this._operation === undefined) {
            throw new Error("Error: Operation not selected");
        }

        const actionId = loaderModel.foregroundActionStart(t("bulkParcelUpload.message.importInProgress"));
        try {
            const importDataLines = await new BulkParcelUploadDataReader(this._operation, file).readData();
            loaderModel.foregroundActionEnd(actionId);

            return importDataLines;
        } catch (err) {
            notificationModel.addErrorMessage(t("bulkParcelUpload.error.importMessage", {
                message: err,
            }));
            loaderModel.foregroundActionEnd(actionId);
            return;
        }
    }

    private async processImport(importDataLines: BulkParcelUploadImportLine[], ordersMap: Map<number, DeliveryOrder>) {
        try {
            await this._importer.cancel();
            await this._importer.import(ordersMap, importDataLines);

            if (this._importer.numberOfProcessedDeliveryOrders === this._importer.numberOfDeliveryOrdersToProcess) {
                notificationModel.addSuccessMessage(t("bulkParcelUpload.message.importSuccess", {count: this._importer.numberOfProcessedDeliveryOrders}));
            } else {
                notificationModel.addWarningMessage(t("bulkParcelUpload.message.importSuccess", {count: this._importer.numberOfProcessedDeliveryOrders}));
            }

            if (this._importer.numberOfProcessedPallets > 0) {
                notificationModel.addSuccessMessage(t("bulkParcelUpload.message.palletImportSuccess", {
                    count: this._importer.numberOfProcessedPallets,
                }));
            }

            if (this._operation && this._batchId) {
                await menuOperationModel.refreshCounters(this._operation.code, this._batchId);
            }
        } catch (err) {
            notificationModel.addErrorMessage(t("bulkParcelUpload.error.importMessage", {
                message: err,
            }));
        }
    }

    private async getDeliveryOrders(operation: Operation, batchId?: number): Promise<Map<number, DeliveryOrder>> {
        return (await (batchId ? this.getDeliveryOrdersForBatch(operation.code, batchId) : this.getDeliveryOrdersForOperation(operation.code)))
            .filter(d => d.status !== "Cancelled")
            .reduce((result: Map<number, DeliveryOrder>, deliveryOrder) => {
                return result.set(deliveryOrder.deliveryOrderId, deliveryOrder);
            }, new Map<number, DeliveryOrder>());
    }

    private async getDeliveryOrdersForBatch(operationCode: string, batchId: number) {
        return await operationsService.loadDeliveryOrderList(operationCode, batchId);
    }

    private async getDeliveryOrdersForOperation(operationCode: string) {
        return (await Promise.all((await this.getBatchIdListForOperation(operationCode))
            .map(batchId => operationsService.loadDeliveryOrderList(operationCode, batchId))))
            .flatMap(deliveryOrder => deliveryOrder);
    }

    private async getBatchIdListForOperation(operationCode: string) {
        return (await operationsService.loadBatches(operationCode)).map(batch => batch.id);
    }

    @action
    public setOperation(operation: Operation) {
        this._operation = operation;
    }

    @action
    public setBatchId(batchId: number | undefined) {
        this._batchId = batchId;
        if (this._batchId) {
            this._exportOperation = false;
        } else {
            this._exportOperation = true;
        }
    }

    public get isOperationSelected() {
        return this._operation !== undefined;
    }

    public get isOperationAndBatchSelected() {
        return this._operation !== undefined && this._batchId !== undefined;
    }

    public parcelExportStrategyIs(strategy: ParcelExportStrategy): boolean {
        return this._parcelExportStrategy === strategy;
    }

    public productExportStrategyIs(strategy: ProductExportStrategy): boolean {
        return this._productExportStrategy === strategy;
    }

    private get exportDataFormat(): IExportDataFormat {
        return context.hasPermission(Permission.FeatureExposeShippingAddress) ? new ShippingAddressExportDataFormat() : new DefaultDataExportDataFormat();
    }

}

export const bulkParcelUploadModel = new BulkParcelUploadModel();
