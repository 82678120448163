import React from "react";
import { ThemeProvider, Paper, Typography } from "@mui/material";
import { VPUITheme } from "../../../common/theme/vpui-theme";
import Barcode from "react-barcode";
import { t } from "i18next";

import "./style.scss";

export const testBarcodes: Array<{
    value: string,
    format: "CODE128" | "CODE39" | "EAN13",
}> = [
    {
        value: "TestValue1230",
        format: "CODE128",
    },
    {
        value: "7612345678900",
        format: "EAN13",
    },
    {
        value: "DS0000001234",
        format: "CODE39",
    },
];

export const BarcodeScannerTestPage = () => {
    return <ThemeProvider theme={VPUITheme}>
        <Paper elevation={0} className={"barcode-scanner-test-page"}>
            <div className={"barcode-scanner-test-page__header"}>
                <Typography className={"barcode-scanner-test-page__header-label"}
                            variant={"h2"}>{t("barcodeScannerTestPage.title")}</Typography>
            </div>
            <div className={"barcode-scanner-test-page__content"}>
                {
                    testBarcodes.map((barcode, index) =>
                        <div className={"barcode-scanner-test-page__content__barcode"}
                             key={`${barcode.format}-${barcode.value}`}>
                            <Typography variant={"h3"}>{`${index + 1}. ${barcode.format}`}</Typography>
                            <Barcode value={barcode.value} format={barcode.format} width={2} height={100}/>
                        </div>)
                }
            </div>
        </Paper>
    </ThemeProvider>;
};
