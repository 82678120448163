import React, { useEffect, useState } from "react";
import { Dialog, IconButton, ThemeProvider} from "@mui/material";
import { PalletShippingScanElements } from "./pallet-shipping-scan-elements";
import { PalletShippingDetails } from "./pallet-shipping-details";
import { VPUITheme } from "../../../../common/theme/vpui-theme";
import { Close } from "@mui/icons-material";
import moment from "moment";
import { PalletShippingValidationHandler } from "./pallet-shipping-validation-handler";
import { PalletShippingValidationStatus } from "./pallet-shipping-validation-response";
import { dialogModel } from "../../../../common/component/dialog/dialog-model";
import { t } from "i18next";
import {
    PalletShippingValidationRequest,
    PalletShippingValidationStrategy,
} from "./pallet-shipping-validation-request";
import { ProgressDialogContent } from "../../../../common/component/progress-dialog/progress-dialog-content";
import { PalletsListItem } from "../pallets-list/pallets-list-item";
import { useWarehouseContext } from "../warehouse-context/warehouse-context-provider";

import "./style.scss";

interface IPalletShippingDialogProps {
    palletList: PalletsListItem[];
    getPalletItemByScan: (palletCode: string) => PalletsListItem | undefined;

    onSubmit: (palletList: PalletsListItem[], truckId: string, pickingDate: moment.Moment,
               sealNumber: string) => void;

    onCancel: () => void;
}

enum PalletShippingDialogMode {
    ProvidePalletListMode,
    ProvideShippingDetailsMode,
}

export const PalletShippingDialog = (props: IPalletShippingDialogProps) => {
    const [shippingPalletsList, setShippingPalletsList] = useState<PalletsListItem[]>(props.palletList);
    const [step, setStep] = useState(props.palletList.length === 0 ?
        PalletShippingDialogMode.ProvidePalletListMode : PalletShippingDialogMode.ProvidePalletListMode);

    const [isValidationInProgress, setIsValidationInProgress] = useState(false);
    const [shippingValidationWarning, setShippingValidationWarning] = useState<string | undefined>();

    const warehouseContext = useWarehouseContext();

    const isScanMode = props.palletList.length === 0;

    const handleScanCompleted = (palletList: PalletsListItem[]) => {
        setShippingPalletsList(palletList);
    };

    useEffect( () => {
        if (shippingPalletsList.length === 0 || !warehouseContext.code) {
            return;
        }

        (async () => {
            setIsValidationInProgress(true);
            const validationResult = await (new PalletShippingValidationHandler())
                .handle(new PalletShippingValidationRequest(shippingPalletsList, PalletShippingValidationStrategy.ForShippingList));

            switch (validationResult.status) {
                case PalletShippingValidationStatus.Success:
                    setStep(PalletShippingDialogMode.ProvideShippingDetailsMode);
                    setIsValidationInProgress(false);
                    break;
                case PalletShippingValidationStatus.Error:
                    dialogModel.alert(t("components.pallet.shipping.shipMultiple"), validationResult.message);
                    props.onCancel();
                    break;
                case PalletShippingValidationStatus.Warning:
                    if (isScanMode) {
                        setShippingValidationWarning(validationResult.message);
                        setStep(PalletShippingDialogMode.ProvidePalletListMode);
                        setIsValidationInProgress(false);
                    } else {
                        dialogModel.alert(t("components.pallet.shipping.shipMultiple"), validationResult.message);
                        props.onCancel();
                    }
                    break;
            }
        })();
    }, [shippingPalletsList.length]);

    return <Dialog open={true}>
        <ThemeProvider theme={VPUITheme}>
            <div className={"pallet-shipping-dialog"}
                 data-testid={"ship-pallet-dialog"}>
                <div className="pallet-shipping-dialog__close">
                    <IconButton onClick={() => props.onCancel()}>
                        <Close />
                    </IconButton>
                </div>
                {isValidationInProgress &&
                    <ProgressDialogContent message={t("components.pallet.shipping.validationInProgress")} />}
                {!isValidationInProgress && step === PalletShippingDialogMode.ProvidePalletListMode &&
                    <PalletShippingScanElements palletList={shippingPalletsList}
                                                palletListWarning={shippingValidationWarning}
                                                getPalletItemByScan={code => props.getPalletItemByScan(code)}
                                                onCancel={() => props.onCancel()}
                                                onFinished={palletList => handleScanCompleted(palletList)} />}
                {!isValidationInProgress && step === PalletShippingDialogMode.ProvideShippingDetailsMode &&
                    <PalletShippingDetails shippingPalletsList={shippingPalletsList}
                                           useExistingShippingInformation={shippingPalletsList[0].type === "Adjustment"}
                                           isTruckIdentifierRequired={false}
                                           onCancel={() => props.onCancel()}
                                           onSubmit={(palletList,
                                                      truckId, pickingDate, sealNumber) =>
                                               props.onSubmit(palletList, truckId, pickingDate, sealNumber)} />}
            </div>
        </ThemeProvider>
    </Dialog>;
};
