import React, { useState } from "react";
import { Dialog, DialogTitle, IconButton } from "@mui/material";
import { SupportTicketForm } from "./support-ticket-form";
import { SupportTicketIssue, SupportTicketLanguage, SupportTicketTechnology } from "./support-ticket-options-provider";
import { CreateSupportTicketCommandHandler } from "../../command/create-support-ticket/create-support-ticket-command-handler";
import { CreateSupportTicketCommand } from "../../command/create-support-ticket/create-support-ticket-command";
import { Close } from "@mui/icons-material";
import { SupportTicketConfirmation } from "./support-ticket-confirmation";
import { t } from "i18next";
import { notificationModel } from "../../../common/component/notifications/notification-model";

import "./style.scss";

interface ISupportTicketProps {
    warehouseCode: string;
    onClose: () => void;
}

export const SupportTicketDialog = (props: ISupportTicketProps) => {
    const [ticketId, setTicketId] = useState<string | undefined>();

    const handleFormSubmit = async (language: SupportTicketLanguage,
                                    issue: SupportTicketIssue,
                                    technology: SupportTicketTechnology,
                                    description: string,
                                    contactName: string,
                                    contactEmail: string,
                                    contactPhone: string,
                                    contactPreferences: string,
                                    attachmentsList: File[]) => {
        try {
            const id = await (new CreateSupportTicketCommandHandler())
                .handle(new CreateSupportTicketCommand(props.warehouseCode, language, issue, technology, description, contactName, contactEmail, contactPhone, contactPreferences,
                                                       attachmentsList));

            setTicketId(id);

            return true;
        } catch (err) {
            notificationModel.addErrorMessage(t("components.supportTicket.error"));

            return false;
        }
    };

    return <Dialog open={true}>
        <div className={"support-ticket"}>
            <div className="support-ticket__close">
                <IconButton onClick={() => props.onClose()}>
                    <Close />
                </IconButton>
            </div>
            <DialogTitle>
                {t("components.supportTicket.title")}
            </DialogTitle>
            {!ticketId ?
                <SupportTicketForm onSubmit={handleFormSubmit} onClose={props.onClose} /> :
                <SupportTicketConfirmation ticketId={ticketId} onClose={props.onClose} />}
        </div>
    </Dialog>;
};
