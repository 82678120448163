import * as React from "react";
import { ReactNode} from "react";

import {
    Avatar,
    Chip,
    Card,
    Drawer,
    Fab,
    Typography, ThemeProvider,
} from "@mui/material";
import { action, computed, IReactionDisposer, reaction } from "mobx";
import { extend, filter, groupBy, map, orderBy, some, split } from "lodash";
import { filterActionCsv, FilterActionData } from "../filter/filter-action-data";
import { FilterData } from "../filter/filter-data";
import { FunnelIcon } from "../../icon/icon-custom";
import { IFieldSort } from "./ifield-sort";
import { Icon } from "../../icon/icon";
import { ScanField } from "../../scan-field/scan-field";
import { SearchListModel } from "./search-list-model";
import { getLabel } from "../../../dto/dto-annotation";
import { getFieldLabel, stringValue } from "../../../field/renderer";
import { t } from "i18next";
import { Cancel, ArrowUpward, ArrowDownward } from "@mui/icons-material";
import { SearchListSelector } from "./search-list-selector";
import { QuickFilterSelect } from "../../quick-filter/quick-filter-select";
import { QuickFilterOption } from "../../quick-filter/quick-filter-option";
import { VPUITheme } from "../../../theme/vpui-theme";
import { ExportMenu } from "../import-export-list/export-menu";

import "./style.scss";

export interface ISearchListRow<T> { id: number | string; details: ISearchListSubRow[]; sortDetailsBy: (detailsPropertyKey: keyof T, sortOrder: "asc" | "desc") => void; }
export interface ISearchListSubRow { additionalDataMap: { [key: string]: string } | undefined; }

export abstract class SearchList<P extends {}, TRow extends ISearchListRow<TSubRow>, TSubRow extends ISearchListSubRow, TFilter extends {}, TState extends {} = {}>
    extends React.Component<P, TState> {

    protected searchListModel: SearchListModel<TRow, TSubRow, TFilter>;

    protected abstract get listIdentifier(): any;

    protected hasMoreElement = false;

    private autoload: IReactionDisposer;
    protected defaultQuickFilterFields: Array<keyof TFilter & keyof TRow> = [];

    constructor(props: P, searchListModel: SearchListModel<TRow, TSubRow, TFilter>) {
        super(props);
        this.searchListModel = searchListModel;
    }

    public async componentWillMount() {
        this.searchListModel.initStore(this.loadDefaultFilter(), filterData => this.parseFilterData(filterData));

        await this.doLoadList(this.listIdentifier);
        this.autoload = reaction(
            () => this.listIdentifier,
            async (listIdentifier: any) => await this.doLoadList(listIdentifier),
        );
    }

    private parseFilterData(filterData: FilterData<any, any, any>): FilterData<TFilter, TRow, TSubRow> {
        filterData.removeFilterActionList(this.sortAndFilterColumnsToIgnore);

        return new FilterData<TFilter, TRow, TSubRow>(
            extend(this.loadDefaultFilter(), filterData.filter),
            filterData.isAndSearch,
            filterData.filterAction,
        );
    }

    protected abstract get sortAndFilterColumnsToIgnore(): string[];

    private async doLoadList(listIdentifier: any) {
        this.searchListModel.resetList([]);
        this.searchListModel.resetList(listIdentifier ? await this.loadList(listIdentifier) : []);
    }

    public async componentWillUnmount() {
        if (this.autoload) {
            this.autoload();
        }
    }

    protected renderListBar(
        total: number, quickFilterLabel: string, tooltipTitle: string, scanFieldType: "text" | "number") {
        return <ThemeProvider theme={VPUITheme}>
            <Card className="cardFilter" elevation={0}>
                <div className="list-bar">
                    <div className="list-bar-left">
                        <div style={{display: "flex"}}>
                            {this.renderFilter(total, quickFilterLabel, tooltipTitle, scanFieldType)}
                            {this.renderExportMenu()}
                        </div>
                        <div className={"quick-filter-list"}>
                            {this.defaultQuickFilterFields.map(f => <QuickFilterSelect title={getFieldLabel(this.searchListModel.filterData.filter, f)}
                                                                                       propertyKey={f.toString()}
                                                                                       loadDefaultValue={() => this.getCurrentFilterValue(f)}
                                                                                       onQuickFilterSelect={value => this.onQuickFilterSelected(f, value)}>
                                {map(groupBy(this.loadQuickFilterOptions([f]), f), (values, v) =>
                                    <QuickFilterOption count={values.length} value={v}>
                                        {v}
                                    </QuickFilterOption>)}
                            </QuickFilterSelect>)}
                            {this.renderAdditionalQuickFiltering()}
                        </div>
                    </div>
                    {this.renderActions()}
                </div>
            </Card>
        </ThemeProvider>;
    }

    protected getCurrentFilterValue(filterKey: keyof TFilter & keyof TRow): string | undefined {
        return this.searchListModel.filterData.filterAction
            .find(a => a.filterField === filterKey) && this.searchListModel.filterData.isAndSearch ?
            String(this.searchListModel.filterData.filter[filterKey]) : undefined;
    }

    protected loadQuickFilterOptions(filterKey: Array<keyof TFilter & (keyof TRow | keyof TSubRow)>) {
        if (!this.searchListModel.filterData.isAndSearch) {
            return this.searchListModel.list;
        }

        const quickFilterData = new FilterData<TFilter, TRow, TSubRow>(
            this.searchListModel.filterData.filter,
            this.searchListModel.filterData.isAndSearch,
            this.searchListModel.filterData.filterAction.filter(f => filterKey.indexOf(f.filterField) === -1));
        return filter(this.searchListModel.list, row => quickFilterData.isInScope(row));
    }

    @action
    protected onQuickFilterSelected(filterKey: keyof TFilter & keyof TRow, value: string) {
        this.searchListModel.applyFilter(new FilterData<TFilter, TRow, TSubRow>(
            this.updateFilterDataModel(filterKey, value, !this.searchListModel.filterData.isAndSearch),
            true,
            this.buildFilterActionDataFor(filterKey, !this.searchListModel.filterData.isAndSearch)));
    }

    private updateFilterDataModel(filterKey: keyof TFilter & keyof TRow, value: string, resetDataModel: boolean) {
        return extend(!resetDataModel ? this.searchListModel.filterData.filter : this.loadDefaultFilter(),
            {[filterKey]: value});
    }

    private buildFilterActionDataFor(filterKey: keyof TFilter & keyof TRow, resetFilterActions: boolean) {
        return [...(!resetFilterActions ?
            this.searchListModel.filterData.filterAction.filter(f => f.filterField !== filterKey) : []),
            ...[new FilterActionData<TFilter, TRow, TSubRow>(filterKey, "equals")]];
    }

    protected renderAdditionalQuickFiltering() {
        return <></>;
    }

    protected renderExportMenu() {
        return <ExportMenu />;
    }

    protected renderSortBar() {
        if (this.searchListModel.isSorterOpened) {
            return this.renderSorterHeaderContent();
        }
        return <tr></tr>;
    }

    private renderFilter(
        total: number, quickFilterLabel: string, tooltipTitle: string, scanFieldType: "text" | "number") {
        return <div className="list-filter">
            <div>
                <div className="marginRight10">
                    {this.renderSelectAll()}
                </div>
                {this.searchListModel.selectedItemsList.length > 0 && <div>
                    <Chip label={t("components.searchList." + (this.searchListModel.selectedItemsList.length > 1 ? "selectedCountPlural" : "selectedCount"), {
                        count: this.searchListModel.selectedItemsList.length,
                    })}
                          data-testid="list-selected-count"
                          className={"list-filter-selected"} />
                </div>}
                <div>
                    <Chip label={t("components.searchList.totalCount" + (total > 1 ? "s" : ""), { count: total })}
                        data-testid="list-total-count" />
                </div>
                <Icon icon="swap_vert" onClick={() => this.searchListModel.clickSort()}
                    tooltipTitle="components.searchList.sortTooltip" tooltipPlacement="top-start" />
                <div>{this.renderSortSelection()}</div>
                <Icon icon={<FunnelIcon className="material-icons" />}
                    onClick={() => this.searchListModel.openFilter()}
                    tooltipTitle="components.searchList.filterTooltip" tooltipPlacement="top-start" />
                <Drawer
                    className="filterDrawer"
                    variant="temporary"
                    anchor="top"
                    open={this.searchListModel.isFilterOpened}
                    onClose={() => this.searchListModel.closeFilter()}>
                    <Fab className="closeButton" onClick={() => this.searchListModel.closeFilter()}>
                        <Icon icon="close" color="primary" />
                    </Fab>
                    {this.renderFilterContent(this.searchListModel.filterData)}
                </Drawer>
            </div>
            <div>
                <ScanField onScan={value => this.applyQuickFilter(value)}
                    label={quickFilterLabel} tooltipTitle={tooltipTitle}
                    scanFieldType={scanFieldType} />
            </div>
            <div className="filterSelection">
                {this.renderFilterSelection()}
                {this.filterActionToDisplay.length > 0 ?
                    <Icon icon="delete" onClick={() => this.applyQuickFilter("")}
                        tooltipTitle="components.searchList.filterSelection.cleanTooltip" />
                    : undefined}
            </div>
        </div>;
    }

    private renderSelectAll() {
        if (this.isSelectionEnabled) {
            return <SearchListSelector
                isAllChecked={this.searchListModel.isAllChecked}
                selectAction={(active: boolean, limit?: number) => this.selectElementsOnFilteredList(active, limit)}
            />;
        }
        return <div />;
    }

    @action
    private selectElementsOnFilteredList(active: boolean, limit?: number) {
        this.searchListModel.selectElements(this.getFilteredList(), active, limit);
    }

    @computed
    private get filterActionToDisplay() {
        return this.searchListModel.filterData.filterAction.filter(f => f.filterAction !== "all");
    }

    protected renderFilterSelection() {
        return this.filterActionToDisplay.map((filterActionData, idx) => {
            const chip = <Chip
                label={this.getChipLabel(filterActionData)}
                onDelete={() => this.searchListModel.removeFilter(filterActionData)}
                key={filterActionData.filterField as string}
                data-testid="filter-sel-chip" />;

            if (idx === 0) {
                return chip;
            }
            return [this.renderOperator(filterActionData.filterField), chip];
        });
    }

    private getChipLabel(filterActionData: FilterActionData<TFilter, TRow, TSubRow>) {
        const label = getLabel(this.searchListModel.filterData.filter, filterActionData.filterField);
        const value = stringValue(this.searchListModel.filterData.filter, filterActionData.filterField);
        if (filterActionData.filterAction === "isTrue" || filterActionData.filterAction === "isFalse") {
            return t("filters.sign." + filterActionData.filterAction, { value: t(label) });
        }
        if (filterActionData.filterAction === "containsItem") {
            return t(label) + " " + t(`filters.sign.${filterActionData.filterAction}`, { value: value.split(",").join(`${t("filters.sign.and")}`) });
        }
        if (filterActionData.filterAction === "notContainItem") {
            return t(label) + " " + t(`filters.sign.${filterActionData.filterAction}`, { value: value.split(",").join(`${t("filters.sign.and")}`) });
        }
        if (filterActionCsv.includes(filterActionData.filterAction)) {
            return t(label) + " " + t(`filters.sign.${filterActionData.filterAction}`, { size: value.split(",").length });
        }
        return t(label) + " " + t("filters.sign." + filterActionData.filterAction, { value });
    }

    private renderOperator(uniqueKey: keyof TFilter & (keyof TRow | keyof TSubRow)) {
        const key = `${this.searchListModel.filterData.isAndSearch ? "and" : "or"}-key-${String(uniqueKey)}`;
        return <div key={key}>
            <Typography variant="body1">
                {this.searchListModel.filterData.isAndSearch ? t("filters.operator.and") : t("filters.operator.or")}
            </Typography>
        </div>;
    }

    protected getList() {
        // apply pagination
        const displayedList = [];
        this.hasMoreElement = false;
        let total = 0;
        for (const filteredRow of this.getFilteredList()) {
            if (total >= this.searchListModel.pageSize) {
                this.hasMoreElement = true;
            } else {
                displayedList.push(this.renderLine(filteredRow));
            }
            total++;
        }
        return { list: displayedList, total };
    }

    protected getFilteredList() {
        const sortedList = this.sortList();
        return filter(sortedList, row => this.searchListModel.filterData.isInScope(row));
    }

    private sortList() {
        if (!this.searchListModel.hasSort) {
            return this.searchListModel.list;
        }

        let sortedList = this.searchListModel.list;
        const sortLevel1 = this.searchListModel.getSortByLevel(1);
        if (sortLevel1) {
            // Sort on priority 1
            sortedList = orderBy(sortedList, [sortLevel1.fieldName], [sortLevel1.sortOrder]);
        }

        const sortLevel2 = this.searchListModel.getSortByLevel(2);
        if (sortLevel2) {
            // Sort on priority 2
            const fieldNames = split(sortLevel2.fieldName, ".", 2);
            if (fieldNames.length !== 2 || fieldNames[0] !== "details") {
                throw new Error("To use a subList sort, you need to define the sort key like 'details.sonProp'");
            }
            //
            sortedList = sortedList.map(row => {
                row.sortDetailsBy(fieldNames[1] as keyof TSubRow, sortLevel2.sortOrder);
                return row;

            });
        }
        return sortedList;
    }

    protected abstract loadDefaultFilter(): TFilter;

    protected abstract prefixLabel: string;
    protected abstract loadList(listIdentifier: any): Promise<TRow[]>;
    protected abstract renderFilterContent(currentFilter: FilterData<TFilter, TRow, TSubRow>): JSX.Element;
    protected abstract renderSorterHeaderContent(): JSX.Element;

    protected abstract renderLine(row: TRow): JSX.Element;
    protected abstract renderActions(): ReactNode;
    protected abstract applyQuickFilter(value: any): void;

    protected abstract get isSelectionEnabled(): boolean;

    protected renderSortSelection() {
        if (this.searchListModel.hasSort) {
            const sortLevel1 = this.searchListModel.getSortByLevel(1);

            if (sortLevel1 &&
                !some(this.sortAndFilterColumnsToIgnore, columnName => columnName === sortLevel1.fieldName)) {
                return <Chip
                    data-testid="sort-chip"
                    avatar={this.getSortAvatar(sortLevel1)}
                    label={t(this.prefixLabel + sortLevel1.fieldName)}
                    deleteIcon={<Cancel data-testid="close-sort-chip" />}
                    onDelete={() => this.searchListModel.resetSort()}
                    key={sortLevel1.priority} />;
            }
        }
        return <div />;
    }

    private getSortAvatar(sortLevel1: IFieldSort) {
        if (sortLevel1.sortOrder === "asc") {
            return <Avatar><ArrowUpward /></Avatar>;
        }
        return <Avatar><ArrowDownward /></Avatar>;
    }
}
