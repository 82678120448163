import { RefObject, useLayoutEffect } from "react";

export const useBrowserBarcodeDetector = (videoElement: RefObject<HTMLVideoElement>,
                                          onValueScanned: (value: string) => void,
                                          valueValidator: (value: string) => boolean
                                              = (_value: string) => true) => {
    const barcodeDetectorInstance = new BarcodeDetector();

    return useLayoutEffect(() => {
        let detectedBarcodes: string[] = [];
        const timer = setInterval((async () => {
            if (!videoElement.current || !videoElement.current.readyState) {
                return;
            }

            const barcodes = (await barcodeDetectorInstance.detect(videoElement.current))
                .filter(barcode => barcode.format !== "qr_code");

            for (const barcode of barcodes) {
                if (valueValidator(barcode.rawValue)) {
                    detectedBarcodes.push(barcode.rawValue);
                    detectedBarcodes = detectedBarcodes.slice(-3);

                    if (barcode.format !== "code_39" ||
                        (barcode.format === "code_39"
                            && detectedBarcodes.length === 3 && detectedBarcodes.every(b => b === barcode.rawValue))
                    ) {
                        onValueScanned(barcode.rawValue);
                        clearInterval(timer);
                    }
                }
            }
        }), 350);

        return () => clearInterval(timer);
    }, [videoElement]);
};
