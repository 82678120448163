import * as i18next from "i18next";

import { reaction } from "mobx";
import { context } from "../context/context";
import { LocaleType } from "../constants/constants";
import * as moment from "moment";

export abstract class I18nManager {

    public init(onChangeLocale: (locale?: LocaleType) => void) {
        moment.locale(context.settings.lang);

        return new Promise<void>((resolve, reject) => {
            i18next.init({
                lng: context.settings.lang,
                resources: {
                    "en-GB": { translation: this.load("en-GB") },
                    "fr-FR": { translation: this.load("fr-FR") },
                    "es-ES": { translation: this.load("es-ES") },
                    "it-IT": { translation: this.load("it-IT") },
                },
            }, err => {
                if (err) {
                    reject(err);
                }
                this.registerOnChangeLocale(onChangeLocale);
                resolve();
            });

            moment.locale(context.settings.lang);
        });
    }

    private registerOnChangeLocale(onChange: (locale?: LocaleType) => void) {
        reaction(
            () => context.settings.lang,
            (lang?: LocaleType) => {
                if (lang) {
                    i18next.changeLanguage(lang, () => onChange(lang));
                }

                moment.locale(context.settings.lang);
            });
    }

    protected abstract load(locale: LocaleType): any;
}
