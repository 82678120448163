import { operationsService } from "../../../services/operations";
import { t } from "i18next";
import { ExportDataItem } from "../../../model/export-data-item";
import { OperationProductExport } from "../../../model/operation-product-export";
import { excelDataExporter } from "../../../../common/component/list/import-export-list/excel-data-exporter";
import { ExportDataContents } from "../../../model/export-data-contents";

export class ExportOperationProductDataHandler {
    private readonly header = [
        t("model.product.id"),
        t("model.product.name"),
        t("model.product.supplierReference"),
        t("model.product.ean"),
        t("model.product.weight"),
        t("model.product.length"),
        t("model.product.width"),
        t("model.product.height")];

    public async handle(operationCode: string) {
        excelDataExporter.export(
            ExportDataContents.rawContentsForSingleWorksheet(`products-list_${operationCode}`,
                new ExportDataItem(this.header), (await operationsService.loadProducts(operationCode))
                    .map(product => this.productToExportItem(product))),
            []);
    }

    private productToExportItem(product: OperationProductExport) {
        return new ExportDataItem([
            product.productId,
            product.productName,
            product.supplierReference,
            product.barcodes.join(", "),
            product.weight,
            product.length,
            product.width,
            product.height,
        ]);
    }
}
