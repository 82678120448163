import React, { useEffect } from "react";

import { matchPath, Route, Routes } from "react-router-dom";
import { IHistoryProps } from "../../../common/navigation/ihistory-props";
import { PalletManagement } from "./pallet-management/pallet-management";
import { PalletShipping } from "./pallet-shipping/pallet-shipping";
import { PalletShipped } from "./pallet-shipped/pallet-shipped";
import { WarehouseSelector } from "./warehouse-selector/warehouse-selector";
import { context } from "../../../context/context";
import { PreparationSectionType } from "../../navigation/preparation-route-props";

export interface IPalletRouterProps extends IHistoryProps {
    setRouteExpanded: (route: PreparationSectionType) => void;
}

export const PalletRouter = (props: IPalletRouterProps) => {
    useEffect(() => {
        props.setRouteExpanded("pallet");
    }, []);

    const matched = matchPath("pallet/:page/:warehouse", props.routing.location.pathname);

    return <>
        {context.currentUser.isSuperAdministrator && <WarehouseSelector routing={props.routing} selectedWarehouse={matched?.params.warehouse} />}
        <Routes>
            <Route path={`pallet-management/:warehouse?`} element={<PalletManagement routing={props.routing} />} />
            <Route path={`pallet-shipping/:warehouse?`} element={<PalletShipping routing={props.routing} />} />
            <Route path={`shipped-pallets/:warehouse?`} element={<PalletShipped routing={props.routing} />} />
        </Routes>
    </>;
};
