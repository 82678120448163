import React, { useState } from "react";
import { Button, CircularProgress, DialogActions, DialogContent, FormControl, FormLabel, MenuItem, Select, TextField } from "@mui/material";
import { SupportTicketIssue, SupportTicketLanguage, SupportTicketOptionsProvider, SupportTicketTechnology } from "./support-ticket-options-provider";
import { t } from "i18next";
import { context } from "../../../context/context";
import { SupportTicketAttachment } from "./support-ticket-attachment";
import { Guid } from "guid-typescript";
import { SupportTicketFormAttachments } from "./support-ticket-form-attachments";

interface ISupportTicketFormProps {
    onSubmit: (language: SupportTicketLanguage,
               issue: SupportTicketIssue,
               technology: SupportTicketTechnology,
               description: string,
               contactName: string,
               contactEmail: string,
               contactPhone: string,
               contactPreferences: string,
               attachmentsList: File[]) => Promise<boolean>;
    onClose: () => void;
}

export const SupportTicketForm = (props: ISupportTicketFormProps) => {
    const optionsProvider = new SupportTicketOptionsProvider();

    const [language, setLanguage] = useState(optionsProvider.defaultLanguageFromContext(context.settings));
    const [issue, setIssue] = useState(SupportTicketIssue.LabelPrinting);
    const [technology, setTechnology] = useState(SupportTicketTechnology.ClickAndShip);
    const [description, setDescription] = useState("");
    const [contactName, setContactName] = useState("");
    const [contactPhone, setContactPhone] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [contactPreferences, setContactPreferences] = useState("");
    const [attachmentsList, setAttachmentsList] = useState<SupportTicketAttachment[]>([]);

    const [isSubmissionInProgress, setSubmissionInProgress] = useState(false);

    const handleFileSelected = (file: SupportTicketAttachment) => {
        setAttachmentsList(list => [...list, ...[file]]);
    };

    const handleFileRemove = (id: Guid) => {
        setAttachmentsList(list => list.filter(i => !i.id.equals(id)));
    };

    const handleFormSubmit = async () => {
        setSubmissionInProgress(true);
        await props.onSubmit(language, issue, technology, description, contactName, contactEmail, contactPhone, contactPreferences,
                             attachmentsList.map(attachment => attachment.file));
        setSubmissionInProgress(false);
    };

    return <>
        <DialogContent className={"support-ticket__form"}>
            <div>
                <FormControl variant={"outlined"} fullWidth>
                    <FormLabel htmlFor={"ticket-issue"}>
                        {t("components.supportTicket.form.issue.label")}
                    </FormLabel>
                    <Select value={issue} id={"ticket-issue"}>
                        {optionsProvider.provideIssueList().map(item =>
                            <MenuItem key={`issue-item-${item}`} value={item} onClick={() => setIssue(item)}>
                                {t(`components.supportTicket.form.issue.${item}`)}
                            </MenuItem>)}
                    </Select>
                </FormControl>
            </div>
            <div className={"support-ticket__group"}>
                <FormControl variant={"outlined"} fullWidth>
                    <FormLabel htmlFor={"ticket-language"}>
                        {t("components.supportTicket.form.language.label")}
                    </FormLabel>
                    <Select value={language} id={"ticket-language"}>
                        {optionsProvider.provideLanguageList().map(item =>
                            <MenuItem key={`language-item-${item}`} value={item} onClick={() => setLanguage(item)}>
                                {t(`components.supportTicket.form.language.${item}`)}
                            </MenuItem>)}
                    </Select>
                </FormControl>
                <FormControl variant={"outlined"} fullWidth>
                    <FormLabel htmlFor={"ticket-language"}>
                        {t("components.supportTicket.form.technology.label")}
                    </FormLabel>
                    <Select value={technology} id={"ticket-language"}>
                        {optionsProvider.provideTechnologyList().map(item =>
                            <MenuItem key={`technology-item-${item}`} value={item} onClick={() => setTechnology(item)}>
                                {t(`components.supportTicket.form.technology.${item}`)}
                            </MenuItem>)}
                    </Select>
                </FormControl>
            </div>

            <div>
                <FormControl variant={"outlined"} fullWidth>
                    <FormLabel htmlFor={"ticket-issue"}>
                        {t("components.supportTicket.form.issue.contactName")}
                    </FormLabel>
                    <TextField id={"contactName"}
                           variant={"outlined"}
                           onChange={e => setContactName(e.target.value)}
                           value={contactName} />
                </FormControl>
            </div>

            <div>
                <FormControl variant={"outlined"} fullWidth>
                    <FormLabel htmlFor={"ticket-issue"}>
                        {t("components.supportTicket.form.issue.contactEmail")}
                    </FormLabel>
                    <TextField id={"contactEmail"}
                           variant={"outlined"}
                           onChange={e => setContactEmail(e.target.value)}
                           value={contactEmail} />
                </FormControl>
            </div>

            <div>
                <FormControl variant={"outlined"} fullWidth>
                    <FormLabel htmlFor={"ticket-issue"}>
                        {t("components.supportTicket.form.issue.contactPhone")}
                    </FormLabel>
                    <TextField id={"contactPhone"}
                           variant={"outlined"}
                           onChange={e => setContactPhone(e.target.value)}
                           value={contactPhone} />
                </FormControl>
            </div>

            <div>
                <FormControl variant={"outlined"} fullWidth>
                    <FormLabel htmlFor={"ticket-issue"}>
                        {t("components.supportTicket.form.issue.contactPreferences")}
                    </FormLabel>
                    <TextField id={"contactPreferences"}
                           variant={"outlined"}
                           onChange={e => setContactPreferences(e.target.value)}
                           value={contactPreferences} />
                </FormControl>
            </div>

            <FormControl variant={"outlined"} fullWidth>
                <FormLabel htmlFor={"ticket-language"}>
                    {t(`components.supportTicket.form.description`)}
                </FormLabel>
                <TextField multiline id={"description"}
                           variant={"outlined"}
                           minRows={8}
                           maxRows={8}
                           onChange={e => setDescription(e.target.value)}
                           value={description} />
            </FormControl>
            <SupportTicketFormAttachments attachmentsList={attachmentsList}
                                          onFileAdded={handleFileSelected}
                                          onFileRemove={handleFileRemove} />
        </DialogContent>
        <DialogActions>
            <Button color={"inherit"}
                    variant={"contained"}
                    onClick={props.onClose}
                    type={"reset"}>
                {t(`components.supportTicket.form.action.cancel`)}
            </Button>
            <Button color={"secondary"}
                    variant={"contained"}
                    type={"submit"}
                    disabled={description.trim() === "" || isSubmissionInProgress}
                    endIcon={isSubmissionInProgress ? <CircularProgress size={"13px"} color={"inherit"} /> : undefined}
                    onClick={async () => await handleFormSubmit()}>
                {t(`components.supportTicket.form.action.submit`)}
            </Button>
        </DialogActions>
    </>;
};
