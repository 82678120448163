import { PrinterDeviceManager } from "../printer-device-manager";
import { remotePrintingService } from "../../../../front-office/services/remote-printing";
import { PageSettings, qzManager } from "../../../../front-office/qz/qz-manager";
import { documentService } from "../../../../front-office/services/document";
import { PrinterDevice } from "../../../model/printer-device";
import { RemoteDocumentJob } from "./remote-document-job";
import { Guid } from "guid-typescript";
import { RemotePrintDocument } from "../../../../front-office/model/remote-print-document";

export class RemoteDocumentsPrinter {
    private readonly _printerDeviceManager = new PrinterDeviceManager();

    public async listAll() {
        const printers = this._printerDeviceManager.list();

        if (printers.length === 0) {
            return [];
        }

        const remotePrintDocuments = await remotePrintingService.remotePrintsForUser();

        return printers.map(device => this.listForPrinter(device, remotePrintDocuments)).flatMap(e => e);
    }

    public listForPrinter(printer: PrinterDevice, remotePrintDocuments: RemotePrintDocument[]) {
        return remotePrintDocuments.filter(d => d.remotePrinterId.equals(printer.id))
            .map(document => new RemoteDocumentJob(document.documentId, printer));
    }

    public async printDocument(printer: PrinterDevice, documentId: Guid) {
        const data = (await documentService.getDocumentFile(documentId.toString())).Value!;

        if (printer.type === "zpl") {
            return await this.printZplDocument(printer.name, data);
        } else {
            return await this.printPdfDocument(printer.name, data);
        }
    }

    public async printPdfDocument(printerName: string, data: File) {
        await qzManager.qzPrintPdfOnPrinter(data, printerName, PageSettings.Default);
    }

    public async printZplDocument(printerName: string, data: File) {
        await qzManager.qzPrintRawFileOnPrinter(data, printerName);
    }
}
