import React from "react";
import { ISvgIcon } from "./svg-icon";

export const PalletAdjustmentIcon = (props: ISvgIcon) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none" className={props.className}>
        <defs>
            <pattern id="chessboard" patternUnits="userSpaceOnUse" width="4" height="4">
                <rect width="2" height="2" fill="currentColor"/>
                <rect x="2" y="2" width="2" height="2" fill="currentColor"/>
            </pattern>
        </defs>
        <rect x="3.1875" y="0.5" width="8.61538" height="8.61538" rx="0.5" stroke="currentColor"/>
        <rect x="5.79688" y="0.5" width="3.3956" height="4.08241" rx="0.5" stroke="currentColor"/>
        <rect x="3.1875" y="11.0768" width="8.61538" height="8.61538" rx="0.5" stroke="currentColor"/>
        <rect x="5.79688" y="11.0768" width="3.3956" height="4.08241" rx="0.5" stroke="currentColor"/>
        <rect x="14.1758" y="0.5" width="8.61538" height="8.61538" rx="0.5" stroke="currentColor"/>
        <rect x="14.1758" y="0.5" width="8.61538" height="8.61538" fill="url(#chessboard)"/>
        <rect x="14.1758" y="11.077" width="8.61538" height="8.61538" rx="0.5" stroke="currentColor"/>
        <rect x="16.7852" y="11.077" width="3.3956" height="4.08241" rx="0.5" stroke="currentColor"/>
        <line x1="2" y1="21.3033" x2="23.978" y2="21.3033" stroke="currentColor" stroke-width="0.8"/>
        <line x1="2" y1="24.6" x2="23.978" y2="24.6" stroke="currentColor" stroke-width="0.8"/>
        <rect x="3.09961" y="21.7033" width="2.74725" height="2.74725" fill="currentColor"/>
        <rect x="20.1318" y="21.7033" width="2.74725" height="2.74725" fill="currentColor"/>
        <rect x="11.6152" y="21.7033" width="2.74725" height="2.74725" fill="currentColor"/>
    </svg>;
};
