export interface IOperationProductExport {
    productId: number;
    productName: string;
    supplierReference: string;
    barcodes: string[];
    weight: number;
    width: number;
    length: number;
    height: number;
}

export class OperationProductExport {
    private readonly _productId: number;
    private readonly _productName: string;
    private readonly _supplierReference: string;
    private readonly _barcodes: string[];
    private readonly _weight: number;
    private readonly _width: number;
    private readonly _length: number;
    private readonly _height: number;

    public constructor(product: IOperationProductExport) {
        this._productId = product.productId;
        this._productName = product.productName;
        this._supplierReference = product.supplierReference;
        this._barcodes = product.barcodes;
        this._weight = product.weight;
        this._width = product.width;
        this._length = product.length;
        this._height = product.height;
    }

    get productId(): number {
        return this._productId;
    }

    get productName(): string {
        return this._productName;
    }

    get supplierReference(): string {
        return this._supplierReference;
    }

    get barcodes(): string[] {
        return this._barcodes;
    }

    get weight(): number {
        return this._weight;
    }

    get width(): number {
        return this._width;
    }

    get length(): number {
        return this._length;
    }

    get height(): number {
        return this._height;
    }
}
