import React from "react";

import { PalletsListItem } from "../pallets-list/pallets-list-item";
import { Typography } from "@mui/material";
import { t } from "i18next";
import { DangerousGoods } from "../../../../constants/constants";
import { red } from "@mui/material/colors";
import { SquaredChip } from "../../../../common/component/squared-chip/squared-chip";

interface IPalletCloseDialogAdrMessageProps {
    palletCodes: string[];
    dangerousGoods: DangerousGoods;
    unCodes?: string[];
}

export interface IPalletCloseDialogAdrProps {
    adrPalletsList: PalletsListItem[];
}

export const PalletCloseDialogAdr = (props: IPalletCloseDialogAdrProps) => {
    const liquidQuantityPallets = props.adrPalletsList.filter(p => p.dangerousGoods === DangerousGoods.LiquidQuantity);
    const lithiumAndLiquidQuantityPallets = props.adrPalletsList.filter(p => p.dangerousGoods !== DangerousGoods.LiquidQuantity);

    return <>
        <Typography className={"pallet-closing-details-summary__adr"}>
            {t("components.pallet.closing.adr.description")}
        </Typography>
        {liquidQuantityPallets.length !== 0 &&
            <PalletCloseDialogAdrMessage palletCodes={liquidQuantityPallets.map(p => p.code)} dangerousGoods={DangerousGoods.LiquidQuantity} />}
        {lithiumAndLiquidQuantityPallets.map(p =>
            <PalletCloseDialogAdrMessage palletCodes={[p.code]} unCodes={p.unCodes} dangerousGoods={p.dangerousGoods} />)}
    </>;
};

const PalletCloseDialogAdrMessage = (props: IPalletCloseDialogAdrMessageProps) => {
    return <>
        <div className={"pallet-closing-details-summary__adr"}>
            {props.palletCodes.map(code => <SquaredChip propertyKey={`item-${code}`} color={red} label={code}/>)}
            {props.dangerousGoods.toString().toUpperCase().replace(",", " / ")} {props.unCodes?.join(", ")}
        </div>
    </>;
};
