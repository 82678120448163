import React, { Fragment, ReactNode, SyntheticEvent, useState } from "react";
import { Button, IconButton, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { PalletsListItem } from "./pallets-list-item";
import { SearchListItemSelector } from "../../../../common/component/list/search-list/search-list-item-selector";
import { SearchListHeaderLabel } from "../../../../common/component/list/search-list/search-list-header-label";
import { Comment, CreateNewFolder, DoubleArrowOutlined, Lock, MoreVert } from "@mui/icons-material";
import { getFieldLabel, stringValue } from "../../../../common/field/renderer";
import { t } from "i18next";
import { PalletAdjustmentIcon } from "../../../../common/icon/pallet-adjustment-icon";

import "./style.scss";

interface IPalletHeaderLineProps {
    pallet: PalletsListItem;
    isItemSelected: boolean;
    isExpanded: boolean;
    displaySingleActionPickerButton: boolean;
    additionalDataSize: number;
    customItemActionComponent?: ReactNode;

    onSelectionToggle: (value: boolean) => void;
    onSingleActionMenuClick: (target: EventTarget) => void;
    onExpandToggle: (isExpanded: boolean) => void;
}

export const PalletHeaderLine = (props: IPalletHeaderLineProps) => {
    const [isCommentVisible, setCommentVisible] = useState(false);

    const handleLineExpandCollapseToggle = (event: SyntheticEvent, isExpanded: boolean) => {
        event.stopPropagation();
        props.onExpandToggle(isExpanded);
    };

    const handleCommentToggle = () => setCommentVisible(value => !value);

    return <Fragment>
        <TableRow key={props.pallet.palletId}
                  data-testid={`pallet-line-${props.pallet.id}`}
                  className={"pallet-header-line search-item__row list-row--level1"}>
            <TableCell colSpan={10 + props.additionalDataSize}
                       className={"search-item__header"}>
                <div className={"search-item__header-contents"}>
                    <SearchListItemSelector onToggle={value => props.onSelectionToggle(value)}
                                            isSelected={props.isItemSelected} />
                    <IconButton onClick={event => handleLineExpandCollapseToggle(event, !props.isExpanded)}
                                data-testid={`expand-toggle-${props.isExpanded ? "expanded" : "collapsed"}`}>
                        <Tooltip title={t(`components.searchList.${props.isExpanded ? "collapse" : "expand"}`)}
                                 placement={"top-start"}>
                            <DoubleArrowOutlined
                                className={`search-item__expand-toggle-icon${!props.isExpanded ? "--collapsed" : ""}`} />
                        </Tooltip>
                    </IconButton>
                    {props.pallet.type === "Regular" ?
                        <RegularPalletIdentifier code={props.pallet.code} /> :
                        <AdjustmentPalletIdentifier code={props.pallet.code} />}
                    <div className={"search-item__header-details"}>
                        <SearchListHeaderLabel label={getFieldLabel(props.pallet, "carrier")}
                                               value={stringValue(props.pallet, "carrier")} />
                        <SearchListHeaderLabel label={getFieldLabel(props.pallet, "country")}
                                               value={stringValue(props.pallet, "country")} />
                        <SearchListHeaderLabel label={getFieldLabel(props.pallet, "parcelCount")}
                                               value={stringValue(props.pallet, "parcelCount")} />
                        <SearchListHeaderLabel label={getFieldLabel(props.pallet, "weight")}
                                               value={stringValue(props.pallet, "weight")} />
                        <SearchListHeaderLabel whenNotEmpty label={getFieldLabel(props.pallet, "pickingDate")}
                                               value={stringValue(props.pallet, "pickingDate")} />
                        <SearchListHeaderLabel label={getFieldLabel(props.pallet, "maxWeight")}
                                               value={palletWeightConstraints(props.pallet)} />
                    </div>
                    <div className={"search-item__header-action"}>
                        {props.pallet.hasComment && <Button onClick={handleCommentToggle}
                                size={"small"}
                                variant={isCommentVisible ? "contained" : "outlined"}>
                            <Comment />
                        </Button>}
                        {props.customItemActionComponent}
                        {props.displaySingleActionPickerButton &&
                            <Button variant={"outlined"}
                                    onClick={event => {
                                        event.stopPropagation();
                                        props.onSingleActionMenuClick(event.target);
                                    }}
                                    size={"small"}>
                                <MoreVert />
                            </Button>}
                    </div>
                </div>
            </TableCell>
        </TableRow>
        {isCommentVisible && <Fragment>
            {props.pallet.creationComment && <TableRow className={"search-item__comment"}>
                <TableCell colSpan={10 + props.additionalDataSize}>
                    <div className={"search-item__comment-wrapper"}>
                        <Typography variant={"h4"} className={"search-item__comment-header list-detail__item list-detail__item--with-icon"}>
                            <span className={"search-item__comment-header-icon"}><CreateNewFolder /></span>
                            <span className={"search-item__comment-header-text"}>{t("model.pallet.creationComment")}</span>
                        </Typography>
                        <Typography>
                            {props.pallet.creationComment}
                        </Typography>
                    </div>
                </TableCell>
            </TableRow>}
            {props.pallet.closingComment && <TableRow className={"search-item__comment"}>
                <TableCell colSpan={10 + props.additionalDataSize}>
                    <div className={"search-item__comment-wrapper"}>
                        <Typography variant={"h4"} className={"search-item__comment-header list-detail__item list-detail__item--with-icon"}>
                            <span className={"search-item__comment-header-icon"}><Lock /></span>
                            <span className={"search-item__comment-header-text"}>{t("model.pallet.closureComment")}</span>
                        </Typography>
                        <Typography>
                            {props.pallet.closingComment}
                        </Typography>
                    </div>
                </TableCell>
            </TableRow>}
        </Fragment>}
    </Fragment>;
};

const RegularPalletIdentifier = (props: {code: string}) => {
    return <div className={"search-item__header-details-id pallet-header-line__id"}>
        <SearchListHeaderLabel propertyKey={"id"}
                               valueClassName={"pallet-header-line__id-value"}
                               label={t("model.pallet.type.regular")}
                               value={props.code} />
    </div>;
};

const AdjustmentPalletIdentifier = (props: {code: string}) => {
    return <Tooltip title={t("palletList.adjustmentPallet")}>
        <div className={"search-item__header-details-id pallet-header-line__id"}>
            <PalletAdjustmentIcon className={"pallet-header-line__adjustment-icon"} />
            <SearchListHeaderLabel propertyKey={"id"}
                                   valueClassName={"pallet-header-line__id-value"}
                                   label={t("model.pallet.type.adjustment")}
                                   value={props.code} />
        </div>
    </Tooltip>;
};

const palletWeightConstraints = (parcel: PalletsListItem) => {
    if (parcel.maxParcelWeight === 0 && parcel.maxWeight === 0) {
        return t("palletParcelList.noWeightLimits");
    }

    if (parcel.maxParcelWeight === 0) {
        return stringValue(parcel, "maxWeight");
    }

    if (parcel.maxWeight === 0) {
        return t("palletParcelList.noTotalWeightWithPerParcelLimit", {
            limit: stringValue(parcel, "maxParcelWeight"),
        });
    }

    return t("palletParcelList.weightLimits", {
        limitTotal: stringValue(parcel, "maxWeight"),
        limitPerParcel: stringValue(parcel, "maxParcelWeight"),
    });
};
