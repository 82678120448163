import * as React from "react";

import { inject, observer } from "mobx-react";
import { DeliveryOrder } from "../../../model/delivery-order";
import { DeliveryOrderList } from "../delivery-order-list/delivery-order-list";
import { IHistoryProps } from "../../../../common/navigation/ihistory-props";
import { SearchListModel } from "../../../../common/component/list/search-list/search-list-model";
import { DeliveryOrderDetail } from "../../../model/delivery-order-detail";
import { DeliveryOrderFilter } from "../../../model/delivery-order-filter";
import { StockoutOrdersDataProvider } from "./stockout-orders-data-provider";
import { OrderDataExporter } from "../delivery-order-list/order-data-exporter";
import { Permission } from "../../../../context/permission";
import { context } from "../../../../context/context";
import { StockoutManagementHeader } from "./stockout-management-header";
import { IActionCommand } from "../../../../common/component/action/action-select/bulky-action-picker";
import { StockoutManagementActionType } from "../../../constants/constants";
import { stockoutProductModel } from "../product-stockout/stockout-product-model";
import { RemoveProductStockout } from "../product-stockout/remove-product-stockout";
import { SingleActionPicker } from "../../../../common/component/action/action-select/single-action-picker";
import { eligibleForBackOfficeValidator } from "../../../../common/component/action/action-select/eligible-for-back-office";
import { stocksService } from "../../../services/stocks";
import { t } from "i18next";
import { notificationModel } from "../../../../common/component/notifications/notification-model";
import { IWithRouterProps, withRouter } from "../../../../common/component/hoc/withRouter";

const stockoutsModel = new SearchListModel<DeliveryOrder, DeliveryOrderDetail, DeliveryOrderFilter>();

interface IStockoutsState {
    singleActionPickerAnchorElement: HTMLElement | null;
    singleActionPickerDeliveryOrderItem: DeliveryOrder | null;
}

@inject("routing")
@observer
class StockoutsComponent extends DeliveryOrderList<IStockoutsState> {
    public state: IStockoutsState = {
        singleActionPickerAnchorElement: null,
        singleActionPickerDeliveryOrderItem: null,
    };

    constructor(props: IWithRouterProps & IHistoryProps) {
        super(props, stockoutsModel, new OrderDataExporter("Stockouts"), new StockoutOrdersDataProvider());
    }

    protected renderActions() {
        return <>
            {context.hasPermission(Permission.BackOffice) && <>
                <StockoutManagementHeader actionsList={this.actionsList} />
                <RemoveProductStockout contentPrefix={"stockoutManagement.action.declareArticleUnstockout"}
                                       operationCode={this.props.params.operationCode}
                                       batchId={+this.props.params.batchId}
                                       reloadDeliveryOrderList={() => this.reloadDeliveryOrderList()} />
                </>}
            {this.state.singleActionPickerAnchorElement &&
                <SingleActionPicker anchorElement={this.state.singleActionPickerAnchorElement}
                                    item={this.state.singleActionPickerDeliveryOrderItem}
                                    list={this.singleActionsList}
                                    onActionComplete={() => this.reloadDeliveryOrderList()}
                                    onPickerClose={() => this.closeSingleActionPickerMenu()} />}
        </>;
    }

    protected get actionsList(): Array<IActionCommand<DeliveryOrder, StockoutManagementActionType>> {
        return [{
            execute: async deliveryOrderIdList => await this.unstockoutDeliveryOrder(deliveryOrderIdList),
            label: "stockoutManagement.action.unstockout.title",
            loadExecuteParam: (isScan, param) => this.loadExecuteParam(isScan, param),
            value: "unstockout",
            actionEligibilityValidator: eligibleForBackOfficeValidator,
        }, {
            execute: async deliveryOrderIdList => await this.startArticleUnstockout(deliveryOrderIdList),
            label: "stockoutManagement.action.declareArticleUnstockout.title",
            loadExecuteParam: (isScan, param) => this.loadExecuteParam(isScan, param),
            value: "declareArticleUnstockout",
            hideExecutionConfirmation: true,
            actionEligibilityValidator: eligibleForBackOfficeValidator,
        }];
    }

    protected openSingleActionPickerMenu(anchor: any, item: DeliveryOrder) {
        this.setState({
            singleActionPickerAnchorElement: anchor,
            singleActionPickerDeliveryOrderItem: item,
        });
    }

    private closeSingleActionPickerMenu() {
        this.setState({
            singleActionPickerDeliveryOrderItem: null,
            singleActionPickerAnchorElement: null,
        });
    }

    private async unstockoutDeliveryOrder(deliveryOrderList: DeliveryOrder[]) {
        const stockouts = await stocksService.bulkUnstockout(this.props.params.operationCode,
            +this.props.params.batchId, deliveryOrderList.map(d => d.id));

        if (stockouts.length === 0) {
            return t(`stockoutManagement.action.unstockout.noItems`);
        }

        const isMultiple = deliveryOrderList.length > 1;
        notificationModel.addSuccessMessage(t(`stockoutManagement.action.unstockout.success` + (isMultiple ? "s" : ""), {
            count: deliveryOrderList.length,
        }));

        await this.reloadDeliveryOrderList();

        return;
    }

    private async startArticleUnstockout(deliveryOrderList: DeliveryOrder[]) {
        if (!deliveryOrderList || deliveryOrderList.length === 0) {
            throw new Error("No data selected, filter should be done previously.");
        }

        stockoutProductModel.openModalForDeliveryOrderIds(deliveryOrderList.map(d => d.id),
            this.getProductsList(deliveryOrderList, "quantityStockout"));
    }

    protected get isSelectionEnabled() {
        return context.hasPermission(Permission.FrontWrite);
    }

    protected get allowOrderPendingCancellationFiltering() {
        return false;
    }
}

export const Stockouts = withRouter(StockoutsComponent);
