import * as errorSound from "../../common/sounds/error.mp3";
import * as areaSound from "../../common/sounds/area.mp3";
import * as labelSound from "../../common/sounds/label.mp3";

export enum Sound {
    Error,
    Success,
    Back,
}

export const useSound = (sound: Sound) => {
    switch (sound) {
        case Sound.Error:
            return (new Audio(errorSound)).play();
        case Sound.Success:
            return (new Audio(areaSound)).play();
        case Sound.Back:
            return (new Audio(labelSound)).play();
    }

    return true;
};
