export interface IOperationProduct {
    operationCode: string;
    productId: number;
    itemId: number;
    productName: string;
    supplierReference: string;
    barcodes: string[];
    width: number;
    height: number;
    length: number;
    weight: number;
    axRefGroupType: string;
    monoParcel: boolean;
}

export class OperationProduct {
    private readonly _operationCode: string;
    private readonly _productId: number;
    private readonly _itemId: number;
    private readonly _productName: string;
    private readonly _suplierReference: string;
    private readonly _barcodes: string[];
    private readonly _width: number;
    private readonly _height: number;
    private readonly _length: number;
    private readonly _weight: number;
    private readonly _axRefGroupType: string;
    private readonly _monoParcel: boolean;

    constructor(operationProduct: IOperationProduct) {
        this._operationCode = operationProduct.operationCode;
        this._productId = operationProduct.productId;
        this._itemId = operationProduct.itemId;
        this._productName = operationProduct.productName;
        this._suplierReference = operationProduct.supplierReference;
        this._barcodes = operationProduct.barcodes;
        this._width = operationProduct.width;
        this._height = operationProduct.height;
        this._length = operationProduct.length;
        this._weight = operationProduct.weight;
        this._axRefGroupType = operationProduct.axRefGroupType;
        this._monoParcel = operationProduct.monoParcel;
    }

    public get operationCode(): string {
        return this._operationCode;
    }

    public get productId(): number {
        return this._productId;
    }

    public get itemId(): number {
        return this._itemId;
    }

    public get productName(): string {
        return this._productName;
    }

    public get supplierReference(): string {
        return this._suplierReference;
    }

    public get barcodes(): string[] {
        return this._barcodes;
    }

    public get width(): number {
        return this._width;
    }

    public get height(): number {
        return this._height;
    }

    public get length(): number {
        return this._length;
    }

    public get weight(): number {
        return this._weight;
    }

    public get axRefGroupType(): string {
        return this._axRefGroupType;
    }

    public get monoParcel(): boolean {
        return this._monoParcel;
    }
}
