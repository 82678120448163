import React, { useEffect, useState } from "react";
import { CarrierCountryGroup } from "../../../carrier-country-group";
import { palletService } from "../../../services/pallet";
import {
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { t } from "i18next";
import { SingleCarrierCountryGroupSelect } from "./single-carrier-country-group-select";
import { MultipleCarrierCountryGroupSelect } from "./multiple-carrier-country-group-select";
import { isTextValidNonNegativeNumber } from "../../../../common/utils/is-text-valid-non-negative-number";
import { CreatePalletRequestCarrierCountryItem } from "./create-pallet-request-carrier-country-item";
import { CreatePalletCommandHandler } from "./create-pallet-command-handler";
import { CreatePalletCommand } from "./create-pallet-command";
import { DialogActionsWithOption } from "../../../../common/component/dialog-actions-with-option/dialog-actions-with-option";
import { DialogActionsOption } from "../../../../common/component/dialog-actions-with-option/dialog-actions-option";
import { DialogActionsButtons } from "../../../../common/component/dialog-actions-with-option/dialog-actions-buttons";

import "./style.scss";

interface ICreatePalletDialogProps {
    warehouse: string;
    onClose: () => void;
    onPalletCreated: () => Promise<void>;
}

enum PalletCreationMode {
    Single,
    Multiple,
}

export const CreatePalletDialog = (props: ICreatePalletDialogProps) => {
    const [warehouse, setWarehouse] = useState("");
    const [carrierCountryGroupList, setCarrierCountryGroupList] = useState<CarrierCountryGroup[]>([]);
    const [palletCreationMode, setPalletCreationMode] = useState(PalletCreationMode.Single);

    const [createPalletRequestCarrierCountryItemList, setCreatePalletRequestCarrierCountryItemList] =
        useState<CreatePalletRequestCarrierCountryItem[]>([]);
    const [maxParcelWeightValue, setMaxParcelWeightValue] = useState("");
    const [maxTotalWeightValue, setMaxTotalWeightValue] = useState("");
    const [creationCommentValue, setCreationCommentValue] = useState("");
    const [printPalletLabelChecked, setPrintPalletLabelChecked] = useState(false);
    const [isAdjustmentPalletChecked, setIsAdjustmentPalletChecked] = useState(false);

    const isMaxParcelWeightValid = maxParcelWeightValue === "" || isTextValidNonNegativeNumber(maxParcelWeightValue);
    const isMaxTotalWeightValid = maxTotalWeightValue === "" || isTextValidNonNegativeNumber(maxTotalWeightValue);

    const isFormValid = isMaxParcelWeightValid && isMaxTotalWeightValid && createPalletRequestCarrierCountryItemList.length > 0;

    useEffect( () => {
        (async () => {
            setWarehouse(props.warehouse);
            setCarrierCountryGroupList(await palletService.loadCarrierCountryList(props.warehouse));
        })();
    }, [props.warehouse]);

    const changePalletCreationMode = (mode: PalletCreationMode) => {
        setPalletCreationMode(mode);
        setCreatePalletRequestCarrierCountryItemList([]);
    };

    const handleCarrierCountryItemChange = (item?: CreatePalletRequestCarrierCountryItem, clearList: boolean = false) => {
        if (item === undefined) {
            setCreatePalletRequestCarrierCountryItemList([]);

            return;
        }

        if (clearList) {
            setCreatePalletRequestCarrierCountryItemList(item.quantity > 0 ? [item] : []);
        } else {
            setCreatePalletRequestCarrierCountryItemList(
                [...createPalletRequestCarrierCountryItemList.filter(i => !i.isSameCarrierCountry(item)),
                    ...(item.quantity > 0 ? [item] : [])]);
        }
    };

    const handleSubmit = async () => {
        await CreatePalletCommandHandler.handle(new CreatePalletCommand(warehouse, createPalletRequestCarrierCountryItemList,
            maxParcelWeightValue !== "" ? Number(maxParcelWeightValue) : 0,
            maxTotalWeightValue !== "" ? Number(maxTotalWeightValue) : 0,
            creationCommentValue, printPalletLabelChecked, isAdjustmentPalletChecked));

        await props.onPalletCreated();
        props.onClose();
    };

    return <Dialog open={true}>
        <div data-testid={"create-pallet-dialog"} className={"create-pallet-dialog"}>
            <div className="create-pallet-dialog__close">
                <IconButton onClick={() => props.onClose()}
                            data-testid={"create-pallet-close"}>
                    <Close />
                </IconButton>
            </div>
            <DialogTitle>{t("components.pallet.createPalletDialog.title")}</DialogTitle>
            <DialogContent className={"create-pallet-dialog__content"}>
                <Typography className={"create-pallet-dialog__description"}>
                    {t("components.pallet.createPalletDialog.description")}
                </Typography>
                <div className={`create-pallet-dialog__form--${palletCreationMode === PalletCreationMode.Single ? "single" : "multiple"}`}>
                    <div className={"create-pallet-dialog__form-group"}>
                        {palletCreationMode === PalletCreationMode.Single &&
                            <SingleCarrierCountryGroupSelect carrierCountryGroupList={carrierCountryGroupList}
                                                             onChange={item =>
                                                                 handleCarrierCountryItemChange(item, true)} />}
                        {palletCreationMode === PalletCreationMode.Multiple &&
                            <MultipleCarrierCountryGroupSelect carrierCountryGroupList={carrierCountryGroupList}
                                                               onChange={item => handleCarrierCountryItemChange(item)} />}
                    </div>
                    <div className={"create-pallet-dialog__form-group--details"}>
                        <FormControl variant={"outlined"} fullWidth>
                            <FormLabel htmlFor={"max-parcel-weight"}>
                                {t("components.pallet.createPalletDialog.maxParcelWeightLabel")}
                            </FormLabel>
                            <TextField id={"max-parcel-weight"}
                                       variant={"outlined"}
                                       data-testid={"create-pallet-max-parcel-weight"}
                                       onChange={e => setMaxParcelWeightValue(e.target.value)}
                                       value={maxParcelWeightValue} />
                            {!isMaxParcelWeightValid &&
                                <FormHelperText data-testid={"create-pallet-max-parcel-weight-error"} error>
                                    {t("components.pallet.createPalletDialog.invalidValue")}
                                </FormHelperText>}
                        </FormControl>
                        <FormControl variant={"outlined"}
                                     fullWidth>
                            <FormLabel htmlFor={"max-total-weight"}>
                                {t("components.pallet.createPalletDialog.maxTotalWeightLabel")}
                            </FormLabel>
                            <TextField id={"max-total-weight"}
                                       variant={"outlined"}
                                       data-testid={"create-pallet-max-total-weight"}
                                       onChange={e => setMaxTotalWeightValue(e.target.value)}
                                       value={maxTotalWeightValue} />
                            {!isMaxTotalWeightValid &&
                                <FormHelperText data-testid={"create-pallet-max-total-weight-error"} error>
                                    {t("components.pallet.createPalletDialog.invalidValue")}
                                </FormHelperText>}
                        </FormControl>
                        <FormControlLabel
                            className={"create-pallet-dialog__label-checkbox"}
                            control={
                                <Checkbox
                                    data-testid={"create-pallet-label"}
                                    color="secondary"
                                    checked={printPalletLabelChecked}
                                    onChange={event => setPrintPalletLabelChecked(event.target.checked)}
                                />
                            }
                            label={t("components.pallet.createPalletDialog.printPalletLabel")}
                        />
                        <FormControlLabel
                            className={"create-pallet-dialog__label-checkbox"}
                            control={
                                <Checkbox
                                    data-testid={"create-pallet-adjustment"}
                                    color="secondary"
                                    checked={isAdjustmentPalletChecked}
                                    onChange={event => setIsAdjustmentPalletChecked(event.target.checked)}
                                />
                            }
                            label={t("components.pallet.createPalletDialog.adjustmentPalletType")}
                        />
                    </div>
                    {palletCreationMode === PalletCreationMode.Single &&
                        <div className={"create-pallet-dialog__comment"}>
                            <FormControl variant={"outlined"} fullWidth>
                                <FormLabel htmlFor={"comment"}>
                                    {t("components.pallet.createPalletDialog.commentLabel")}
                                </FormLabel>
                                <TextField id={"comment"}
                                           variant={"outlined"}
                                           data-testid={"create-pallet-comment"}
                                           multiline
                                           minRows={4}
                                           maxRows={4}
                                           inputProps={{maxLength: 255}}
                                           onChange={e => setCreationCommentValue(e.target.value)}
                                           value={creationCommentValue} />
                            </FormControl>
                        </div>}
                </div>
            </DialogContent>
            <DialogActionsWithOption>
                <DialogActionsOption>
                    <FormControlLabel control={
                        <Checkbox checked={palletCreationMode === PalletCreationMode.Multiple}
                                  onChange={(_event, checked) =>
                                      changePalletCreationMode(checked ?
                                          PalletCreationMode.Multiple : PalletCreationMode.Single)} />}
                                      label={t("components.pallet.createPalletDialog.multiplePalletMode")}
                                      data-testid={"create-pallet-mode-switch"} />
                </DialogActionsOption>
                <DialogActionsButtons>
                    <Button variant="contained"
                            color={"inherit"}
                            data-testid="create-pallet-cancel"
                            onClick={() => props.onClose()}
                            key="cancel-btn">
                        {t(`components.popin.cancel`)}
                    </Button>
                    <Button color="secondary"
                            variant="contained"
                            type="submit"
                            data-testid="create-pallet-submit"
                            autoFocus key="submit-btn"
                            disabled={!isFormValid}
                            onClick={async () => await handleSubmit()}>
                        {t("components.pallet.createPalletDialog.submit")}
                    </Button>
                </DialogActionsButtons>
            </DialogActionsWithOption>
        </div>
    </Dialog>;
};
