import { t } from "i18next";
import { isObject } from "lodash";
import { HttpMethodType } from "../../constants/constants";
import { context } from "../../context/context";
import { authenticationService } from "../../services/authentication";
import { loaderModel } from "../component/loader/loader-model";
import { notificationModel } from "../component/notifications/notification-model";
import { DropNetError } from "./error";
import { DropNetResponse } from "./response";

const rootUrl = "/api";
const defaultVersionApi = "/v3";
const ContentTypeHeader = "Content-Type";
const AcceptLanguageHeader = "Accept-Language";
const AuthorizationHeader = "Authorization";
const SourceSystemHeader = "Source-System";

interface IRequestConfig {
    method: HttpMethodType;
    retry?: boolean;
}

export enum ContentType {
    TEXT_PLAIN = "text/plain",
    APPLICATION_JSON = "application/json",
}

export interface IFetchOptions {
    guid?: string;
    url: string;
    contentType?: ContentType;
    versionApi?: boolean;
    data?: {};
    failsOnUnauthorized?: boolean;
    displayNotificationError?: boolean;
    customToken?: string;
}

async function coreFetch(requestConfig: IRequestConfig & IFetchOptions): Promise<any> {
    await context.refreshSsoTokenIfSsoEnabled();

    if (requestConfig.retry) {
        // replace old token by spare token
        context.switchCurrentUserTokens();
    }

    const response = await coreFetchDropNetResponse(requestConfig);
    if (response.succeeded) {
        if (requestConfig.retry) {
            // Get new spare token
            const newSpareToken = await authenticationService.token();
            context.updateCurrentUserSpareToken(newSpareToken.access_token);
        }

        return response.Value;
    } else {
        if (!context.currentUser.isAuthenticatedWithSso && !requestConfig.failsOnUnauthorized && !requestConfig.retry && response.Errors.some(error => error.Code === 401)) {
            requestConfig.retry = true;
            return await coreFetch(requestConfig);
        } else {
            if (requestConfig.displayNotificationError) {
                response.Errors.forEach(error => notificationModel.addTranslatedErrorMessage(error.Description));
            }
            throw new Error(response.ErrorDescription);
        }
    }
}

async function coreFetchDropNetResponse(requestConfig: IRequestConfig & IFetchOptions): Promise<DropNetResponse<any>> {
    const { method, url, versionApi, data, failsOnUnauthorized } = requestConfig;

    const body = data ? JSON.stringify(data) : undefined;

    const headers: { [key: string]: string } = {};

    headers[SourceSystemHeader] = context.applicationName;

    if (context.settings.lang) {
        headers[AcceptLanguageHeader] = context.settings.lang;
    }
    if (data) {
        headers[ContentTypeHeader] = requestConfig.contentType ? requestConfig.contentType :
            (isObject(data) ? ContentType.APPLICATION_JSON : ContentType.TEXT_PLAIN);
    }
    if (context.isAuthenticated) {
        headers[AuthorizationHeader] = `Bearer ${context.currentUser.token}`;
    }
    if (!!requestConfig.customToken) {
        headers[AuthorizationHeader] = `Bearer ${requestConfig.customToken}`;
    }

    const actionId = loaderModel.backgroundActionStart(url);
    try {
        let urlToCall = rootUrl + defaultVersionApi + url;
        if (versionApi !== undefined && versionApi === false) {
            urlToCall = rootUrl + url;
        }
        const request = await fetch(urlToCall, { method, body, headers, credentials: "include" });

        if (request.status === 204) {
            // no data to parse
            return new DropNetResponse(true);
        } else if (request.status >= 400) {
            if (!failsOnUnauthorized && requestConfig.retry) {
                context.reset();
                location.reload();
            }
            return DropNetResponse.Failed(await parseDropNetErrorsFromResponseAsync(request));
        } else if (request.headers.get("Content-Type") === "application/pdf" ||
            request.headers.get("Content-Type") === "application/octet-stream") {
            return new DropNetResponse(true, await parsePdfResponseAsync(request));
        } else {
            return new DropNetResponse(true, await parseTextResponseAsync(request));
        }
    } catch (error) {
        return DropNetResponse.Failed([new DropNetError(500, error.message)]);
    } finally {
        loaderModel.backgroundActionEnd(actionId);
    }
}

async function parseDropNetErrorsFromResponseAsync(request: Response) {
    const genericErrorArray = [new DropNetError(request.status, t("error.general.unexpectedError"))];
    const parsedResponse = await parseTextResponseAsync(request);

    if (!parsedResponse) {
        return genericErrorArray;
    }

    const errors: DropNetError[] = [];
    (!Array.isArray(parsedResponse) ? [parsedResponse] : parsedResponse).forEach(error => {
        if (error.toString() === error) {
            errors.push(new DropNetError(request.status, error));
        }
        if (error.message) {
            errors.push(new DropNetError(request.status, error.message));
        }
    });

    return errors.length ? errors : genericErrorArray;
}

async function parsePdfResponseAsync(request: Response) {
    const disposition = (request.headers.get("Content-Disposition") as string).match(/filename=(.*?);/i);
    let filename = "label.pdf";
    if (disposition !== null && disposition.length === 2) {
        filename = disposition[1];
    }
    const result = await request.blob();
    const b: any = result;

    b.lastModifiedDate = new Date();
    b.name = filename;

    return result;
}

async function parseTextResponseAsync(request: Response) {
    const result = await request.text();
    if (!result) {
        return undefined;
    }
    const contentType = request.headers.get("Content-Type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
        return JSON.parse(result);
    }
    return result;
}

export async function httpDelete<T>(options: IFetchOptions, displayNotificationError: boolean = true): Promise<T> {
    return coreFetch({
        method: "DELETE",
        url: options.url,
        data: options.data,
        versionApi: options.versionApi,
        customToken: options.customToken,
        displayNotificationError,
        retry: false,
    });
}

export async function httpGet<T>(options: IFetchOptions, displayNotificationError: boolean = true): Promise<T> {
    return await coreFetch({
        method: "GET",
        url: options.url,
        contentType: options.contentType,
        versionApi: options.versionApi,
        customToken: options.customToken,
        displayNotificationError,
    });
}

export async function httpGetList<T>(options: IFetchOptions,
                                     displayNotificationError: boolean = true): Promise<T[]> {
    return await coreFetch({
        method: "GET",
        url: options.url,
        versionApi: options.versionApi,
        customToken: options.customToken,
        contentType: options.contentType,
        displayNotificationError,
    }) || [];
}

export async function httpPost<T>(options: IFetchOptions,
                                  displayNotificationError: boolean = true): Promise<T> {
    return coreFetch({
        method: "POST",
        url: options.url,
        data: options.data,
        contentType: options.contentType,
        failsOnUnauthorized: options.failsOnUnauthorized,
        versionApi: options.versionApi,
        customToken: options.customToken,
        retry: false,
        displayNotificationError,
    });
}

export async function httpPut<T>(options: IFetchOptions,
                                 displayNotificationError: boolean = true): Promise<T> {
    return coreFetch({
        method: "PUT",
        url: options.url,
        data: options.data,
        contentType: options.contentType,
        versionApi: options.versionApi,
        customToken: options.customToken,
        displayNotificationError,
    });
}

export async function httpPatch<T>(options: IFetchOptions,
                                   displayNotificationError: boolean = true): Promise<T> {
    return coreFetch({
        method: "PATCH",
        url: options.url,
        data: options.data,
        contentType: options.contentType,
        versionApi: options.versionApi,
        customToken: options.customToken,
        displayNotificationError,
    });
}

export async function httpFileRequest(method: HttpMethodType, url: string, displayNotificationError: boolean = true, data?: {}): Promise<DropNetResponse<File>> {
    return coreFetchDropNetResponse({ method, url, retry: false, data, displayNotificationError });
}
