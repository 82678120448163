import { sortBy } from "lodash";
import { Parcel } from "../../../model/parcel";
import { context } from "../../../../context/context";
import { loaderModel } from "../../../../common/component/loader/loader-model";
import { notificationModel } from "../../../../common/component/notifications/notification-model";
import { printerProgressDrawerModel } from "./printer-progress-drawer-model";
import { PageSettings, qzManager } from "../../../qz/qz-manager";
import { t } from "i18next";
import { IParcelDocumentProvider } from "./document-provider/parcel-document-provider-interface";
import { IParcelDocument } from "./document-provider/parcel-document";
import { IMultiParcelDocumentProvider } from "./document-provider/multiparcel-document-provider-interface";
import { ParcelPrintSummary } from "./parcel-print-summary";
import { MultiparcelPrintSummary } from "./multiparcel-print-summary";
import { PrinterExtensionType } from "../../../../constants/constants";
import { customFilenameProvider } from "./document-provider/custom-filename-provider";

export enum DocumentFormat {
    Pdf = 1,
    PdfLandscape = 2,
    Zebra = 3,
}

export enum DocumentPrintoutMode {
    UserDefined = 0,
    ForceDownload = 1,
    ForcePrint = 2,
}

class DocumentPrinter {
    public async printOrDownloadWithProgressDrawer(
        operationCode: string,
        batchId: number,
        parcelList: Parcel[],
        documentStrategy: IParcelDocumentProvider,
        documentPrintoutMode: DocumentPrintoutMode = DocumentPrintoutMode.UserDefined,
        filenameFormat: string = "",
    ): Promise<ParcelPrintSummary> {
        return this.printOrDownloadAllWithProgressDrawer(operationCode, batchId, parcelList, [documentStrategy], documentPrintoutMode, filenameFormat);
    }

    public async printOrDownloadAllWithProgressDrawer(
        operationCode: string,
        batchId: number,
        parcelList: Parcel[],
        documentStrategyList: IParcelDocumentProvider[],
        documentPrintoutMode: DocumentPrintoutMode = DocumentPrintoutMode.UserDefined,
        filenameFormat: string = "",
    ): Promise<ParcelPrintSummary> {
        // Check before printing to avoid parcel status change and label fails to print.
        if (!context.settings.isDownload && documentPrintoutMode !== DocumentPrintoutMode.ForceDownload) {
            const error = await qzManager.checkPrintConfig();
            if (error) {
                notificationModel.addErrorMessage(error);

                return ParcelPrintSummary.emptyResponse();
            }
        }

        printerProgressDrawerModel.onStartPrinting(parcelList, documentStrategyList);
        const actionId = loaderModel.backgroundActionStart("smartPrint");
        const printedParcelsList: Parcel[] = [];

        for (const i in sortBy(parcelList, p => p.deliveryOrderId)) {
            if (parcelList.hasOwnProperty(i)) {
                if (!printerProgressDrawerModel.isPrintCanceled) {
                    const parcel = parcelList[i];

                    const documents = await Promise.all(documentStrategyList
                        .map(provider => provider.fetch(operationCode, batchId, parcel)));
                    if (documents.every(d => d.hasContents)) {
                        await this.sendDocumentsToUser(documents, documentPrintoutMode, customFilenameProvider.composeFilename(filenameFormat, parcel));
                        printerProgressDrawerModel.completeParcelDocumentPrintout();
                        printedParcelsList.push(parcel);
                    } else {
                        printerProgressDrawerModel.addParcelPrintoutErrors(parcel);
                    }
                }
            } else {
                break;
            }
        }

        printerProgressDrawerModel.onEndPrinting();
        loaderModel.backgroundActionEnd(actionId);

        return new ParcelPrintSummary(printedParcelsList, printerProgressDrawerModel.nbParcelPrinted,
            printerProgressDrawerModel.nbPreparationOrderPrinted);
    }

    public async downloadMultiparcelDocument(
        operationCode: string,
        batchId: number,
        parcelList: Parcel[],
        parcelDocumentProvider: IMultiParcelDocumentProvider,
    ): Promise<MultiparcelPrintSummary> {
        const actionId = loaderModel.foregroundActionStart(t("components.customDocumentPrint.printingInProgress", { parcelsCount: parcelList.length }));

        const documentsResponse = await parcelDocumentProvider.fetchMultiparcel(operationCode, batchId, parcelList);

        loaderModel.foregroundActionEnd(actionId);

        if (documentsResponse.contents) {
            await this.sendDocumentToUser(documentsResponse, DocumentPrintoutMode.ForceDownload);
        }

        return new MultiparcelPrintSummary(documentsResponse.parcelIds.length, parcelList.length - documentsResponse.parcelIds.length);
    }

    private async sendDocumentsToUser(documents: IParcelDocument[], documentPrintoutMode: DocumentPrintoutMode, filename: string) {
        for (const document of documents) {
            await this.sendDocumentToUser(document, documentPrintoutMode, filename);
        }
    }

    private async sendDocumentToUser(document: IParcelDocument, documentPrintoutMode: DocumentPrintoutMode, filename: string = "") {
        switch (documentPrintoutMode) {
            case DocumentPrintoutMode.UserDefined:
                await qzManager.qzPrintOrDownloadFile(document.contents!, this.printerExtensionType(document.documentFormat), filename);
                break;
            case DocumentPrintoutMode.ForceDownload:
                await qzManager.downloadFile(document.contents!, filename);
                break;
            case DocumentPrintoutMode.ForcePrint:
                await qzManager.qzPrintFile(document.contents!, this.printerExtensionType(document.documentFormat), this.documentPrintSettings(document.documentFormat));
                break;
        }
    }

    private documentPrintSettings(format: DocumentFormat): PageSettings | undefined {
        switch (format) {
            case DocumentFormat.Pdf:
                return PageSettings.Default;
            case DocumentFormat.PdfLandscape:
                return PageSettings.A4WithAutoOrientation;
            default:
                return undefined;
        }
    }

    private printerExtensionType(format: DocumentFormat): PrinterExtensionType {
        switch (format) {
            case DocumentFormat.Pdf:
            case DocumentFormat.PdfLandscape:
                return "Pdf";
            case DocumentFormat.Zebra:
                return "Zpl";
            default:
                throw Error("Unknown document format");
        }
    }
}

export const documentPrinter = new DocumentPrinter();
