import * as React from "react";
import { PropsWithChildren } from "react";

import { inject, observer } from "mobx-react";

import { IHistoryProps } from "./ihistory-props";
import { Navigate } from "react-router-dom";
import { context } from "../../context/context";
import { notificationModel } from "../component/notifications/notification-model";
import { Permission } from "../../context/permission";

interface IAuthenticatedRouteProps {
    permission: Permission;
}

@inject("routing")
@observer
export class AuthenticatedRoute extends React.Component<PropsWithChildren<IHistoryProps & IAuthenticatedRouteProps>> {

    public render() {
        if (!context.isAuthenticated) {
            return this.unauthorizeRendering();
        }
        if (!context.hasPermission(this.props.permission)) {
            notificationModel.addErrorMessage("error.missingPermission", { userName: context.currentUser.name });
            return this.unauthorizeRendering();
        }
        return <>{this.props.children}</>;
    }

    private unauthorizeRendering() {
        return <Navigate to={"/login"} state={{from: this.props.routing.location.pathname}} replace />;
    }
}
