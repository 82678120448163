import { IParamActionValidator } from "../../../../common/component/action/action-select/interface-param-action-validator";
import {
    ParamActionValidationResult,
} from "../../../../common/component/action/action-select/param-action-validation-result";
import { PalletsListItem } from "../pallets-list/pallets-list-item";
import { t } from "i18next";

class MixedPalletTypeParamActionValidator implements IParamActionValidator<PalletsListItem> {
    public validate(pallets: PalletsListItem[]) {
        if (pallets.some(p => p.type !== pallets[0].type)) {
            return ParamActionValidationResult.error(t("palletShipping.action.ship.validation.mixedPalletType"));
        }

        return ParamActionValidationResult.success(pallets);
    }
}

export const mixedPalletTypeParamActionValidator = new MixedPalletTypeParamActionValidator();
