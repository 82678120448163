import React, { useEffect, useState } from "react";
import { Button, CardContent, FormControl, FormHelperText, FormLabel, TextField, Typography } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { PasswordRequirementsValidator } from "./password-requirements-validator";
import { usersService } from "../../../back-office/services/users";
import { t } from "i18next";

interface IResetPasswordFormProps {
    token: string;
    userName: string;
    onComplete: () => void;
    onCancel: () => void;
}

export const ResetPasswordForm = (props: IResetPasswordFormProps) => {
    const [passwordValue, setPasswordValue] = useState("");
    const [confirmPasswordValue, setConfirmPasswordValue] = useState("");

    const [passwordValidated, setPasswordValidated] = useState(false);
    const [passwordChangeError, setPasswordChangeError] = useState(false);

    useEffect(() => setPasswordChangeError(false), [passwordValue, confirmPasswordValue]);

    const handlePasswordChange = async (event: React.ChangeEvent<{}>) => {
        event.preventDefault();

        try {
            await usersService.resetPasswordChange(props.userName, props.token, passwordValue);
            props.onComplete();
        } catch (e) {
            setPasswordChangeError(true);
        }
    };

    return <form onSubmit={async event => await handlePasswordChange(event)} autoComplete={"off"}>
        <CardContent>
            <FormControl className={"password-reset__form-element-container"}
                         variant={"outlined"}
                         fullWidth>
                <FormLabel className={"password-reset__form-element"}>
                    <Typography className={"password-reset__form-label"}>
                        {t("model.login.login")}
                    </Typography>
                    <TextField value={props.userName}
                               className={"password-reset__form-input"}
                               InputProps={{ readOnly: true }}
                               variant={"outlined"} />
                </FormLabel>
            </FormControl>
            <PasswordRequirementsValidator password={passwordValue} onChange={setPasswordValidated} />
            <FormControl className={"password-reset__form-element-container"}
                         variant={"outlined"}
                         fullWidth>
                <FormLabel className={"password-reset__form-element"}>
                    <Typography className={"password-reset__form-label"}>
                        {t("model.login.passsword")}
                    </Typography>
                    <TextField type={"password"}
                               className={"password-reset__form-input"}
                               autoComplete={"new-password"}
                               value={passwordValue}
                               variant={"outlined"}
                               onChange={event => setPasswordValue(event.target.value ?? "")}
                    />
                </FormLabel>
            </FormControl>
            <FormControl className={`password-reset__form-element-container${confirmPasswordValue && (passwordValue !== confirmPasswordValue) ? "--with-error" : ""}`}
                         variant={"outlined"}
                         fullWidth>
                <FormLabel className={"password-reset__form-element"}>
                    <Typography className={"password-reset__form-label"}>
                        {t("components.passwordChange.confirmPassword")}
                    </Typography>
                    <TextField type={"password"}
                               className={"password-reset__form-input"}
                               autoComplete={"off"}
                               value={confirmPasswordValue}
                               variant={"outlined"}
                               onChange={event => setConfirmPasswordValue(event.target.value ?? "")}
                    />
                </FormLabel>
                {confirmPasswordValue && (passwordValue !== confirmPasswordValue) && <FormHelperText className={"password-reset__form-error"} error>
                    <Cancel />
                    <Typography component={"span"} className={"password-reset__form-error-text"}>
                        {t("components.passwordChange.error.passwordConfirmInvalid")}
                    </Typography>
                </FormHelperText>}
                {passwordChangeError && <FormHelperText className={"password-reset__form-error"} error>
                    <Cancel />
                    <Typography component={"span"} className={"password-reset__form-error-text"}>
                        {t("components.passwordChange.error.passwordSubmit")}
                    </Typography>
                </FormHelperText>}
            </FormControl>
            <div className={"password-reset__button-container"}>
                <Button variant={"contained"}
                        color={"secondary"}
                        onClick={event => handlePasswordChange(event)}
                        disabled={!passwordValidated || passwordValue !== confirmPasswordValue}
                        key={"submit-button"}>
                    {t("components.passwordChange.submit")}
                </Button>
                <Button variant={"contained"}
                        color={"inherit"}
                        onClick={() => props.onCancel()}
                        key={"cancel-btn"}>
                    {t("components.passwordChange.cancel")}
                </Button>
            </div>
        </CardContent>
    </form>;
};
