import {
    Button, CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel, MenuItem, Select,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { sumBy } from "lodash";
import { SquaredChip } from "../../../../common/component/squared-chip/squared-chip";
import { blue } from "@mui/material/colors";
import { t } from "i18next";
import moment from "moment";
import { PalletsListItem } from "../pallets-list/pallets-list-item";
import { PalletShippingInformation } from "../../../model/pallet-shipping-information";
import { palletService } from "../../../services/pallet";
import { context } from "../../../../context/context";
import { useEffectAsync } from "../../../../common/hooks/use-effect-async";

import "./style.scss";

interface IPalletShippingDetailsProps {
    shippingPalletsList: PalletsListItem[];
    isTruckIdentifierRequired: boolean;
    useExistingShippingInformation: boolean;

    onCancel: () => void;
    onSubmit: (palletList: PalletsListItem[], truckId: string, pickingDate: moment.Moment, sealNumber: string) => void;
}

interface IPalletShippingDetailsTitleProps {
    shippingPalletsList: PalletsListItem[];
}

interface IPalletShippingInformationProps {
    onChanged: (truckId: string, pickingDate: moment.Moment, sealNumber: string) => void;
}

export const PalletShippingDetails = (props: IPalletShippingDetailsProps) => {
    const [truckId, setTruckId] = useState("");
    const [pickingDate, setPickingDate] = useState(moment());
    const [sealNumber, setSealNumber] = useState("");
    const [shippingInformationSelected, setShippingInformationSelected] = useState(false);

    const isFormValid = (!props.isTruckIdentifierRequired || truckId !== "") && (!props.useExistingShippingInformation || shippingInformationSelected);

    const handleShippingInformationSelect = (truckIdentifier: string, pickupDate: moment.Moment, containerSealNumber: string) => {
        setTruckId(truckIdentifier);
        setPickingDate(pickupDate);
        setSealNumber(containerSealNumber);
        setShippingInformationSelected(true);
    };

    const handleFormSubmission = () => {
        props.onSubmit(props.shippingPalletsList, truckId, pickingDate, sealNumber);
    };

    return <>
        <PalletShippingDetailsTitle shippingPalletsList={props.shippingPalletsList} />
        <DialogContent>
            <div className={"pallet-shipping-summary"}>
                <Typography>
                    {t("components.pallet.shipping.description", {
                        count: props.shippingPalletsList.length,
                    })}
                </Typography>
                <div className={"pallet-shipping-summary__list"}>
                    {props.shippingPalletsList.map(c => <SquaredChip propertyKey={`item-${c.code}`} color={blue} label={c.code} />)}
                </div>
                <Typography className={"pallet-shipping-details__summary"}>
                    {t("components.pallet.shipping.descriptionParcelCount", {
                        count: sumBy(props.shippingPalletsList, "parcelCount"),
                    })}
                </Typography>
            </div>
        </DialogContent>
        <DialogContent>
            <div className={"pallet-shipping-form"}>
                <Typography className={"pallet-shipping-form__headline"}>
                    {t("components.pallet.shipping.informationTitle")}
                </Typography>
                <Typography>
                    {t("components.pallet.shipping.informationDetails")}
                </Typography>
                {!props.useExistingShippingInformation && <PalletShippingInformationInput onChanged={handleShippingInformationSelect} /> }
                {props.useExistingShippingInformation && <PalletShippingInformationSelect onChanged={handleShippingInformationSelect} />}
            </div>
        </DialogContent>
        <DialogActions>
            <Button color={"inherit"}
                    variant="contained"
                    type="submit"
                    data-testid={"pallet-shipping-cancel"}
                    onClick={async () => props.onCancel()}>
                {t("components.popin.cancel")}
            </Button>
            <Button color="secondary"
                    variant="contained"
                    type="submit"
                    data-testid={"pallet-shipping-submit"}
                    key="submit-btn"
                    disabled={!isFormValid}
                    onClick={() => handleFormSubmission()}>
                {t("components.pallet.shipping.ship")}
            </Button>
        </DialogActions>
    </>;
};

const PalletShippingDetailsTitle = (props: IPalletShippingDetailsTitleProps) => {
    return <DialogTitle>
        {props.shippingPalletsList.length > 1 ?
            t("components.pallet.shipping.multiplePalletsTitle", {
                count: props.shippingPalletsList.length,
            }) :
            t("components.pallet.shipping.singlePalletTitle", {
                palletCode: props.shippingPalletsList[0].code,
            })}
    </DialogTitle>;
};

const PalletShippingInformationInput = (props: IPalletShippingInformationProps) => {
    const [truckId, setTruckId] = useState("");
    const [pickingDate] = useState(moment());
    const [sealNumber, setSealNumber] = useState("");

    const handleOnInput = (value: string, setValue: React.Dispatch<React.SetStateAction<string>>) => {
        setValue(value);

        props.onChanged(truckId, moment(pickingDate), sealNumber);
    };

    return <div className={"pallet-shipping-form__container"}>
        <FormControl variant={"outlined"}
                     fullWidth>
            <FormLabel htmlFor={"ship-picking-date"}>
                {t("components.pallet.shipping.pickingDate")}
            </FormLabel>
            <TextField id="ship-picking-date"
                       variant={"outlined"}
                       data-testid={"ship-picking-date"}
                       value={pickingDate.format("Y-MM-DD")}
                       disabled={true}/>
        </FormControl>
        <FormControl variant={"outlined"}
                     fullWidth>
            <FormLabel htmlFor={"ship-truck-id"}>
                {t("components.pallet.shipping.truckId")}
            </FormLabel>
            <TextField id={"ship-truck-id"}
                       variant={"outlined"}
                       data-testid={"ship-truck-id"}
                       onChange={event => handleOnInput(event.target.value, setTruckId)}
                       value={truckId}/>
        </FormControl>
        <FormControl variant={"outlined"}
                     fullWidth>
            <FormLabel htmlFor={"ship-truck-id"}>
                {t("components.pallet.shipping.containerSealNumber")}
            </FormLabel>
            <TextField id={"ship-truck-id"}
                       variant={"outlined"}
                       data-testid={"ship-truck-id"}
                       onChange={event => handleOnInput(event.target.value, setSealNumber)}
                       value={sealNumber}/>
        </FormControl>
    </div>;
};

const PalletShippingInformationSelect = (props: IPalletShippingInformationProps) => {
    const [shippingInformationList, setShippingInformationList] = useState<PalletShippingInformation[]>([]);
    const [shippingId, setShippingId] = useState("placeholder");
    const [loadingData, setLoadingData] = useState(true);

    useEffectAsync(async () => {
        setShippingInformationList(await palletService.loadShippingInformation(context.warehouseCode));
        setLoadingData(false);
    }, []);

    const handleOnSelect = (id: string) => {
        setShippingId(id);
        const shippingInformation = shippingInformationList?.find(si => si.id === id);

        if (shippingInformation) {
            props.onChanged(shippingInformation.truckIdentifier, moment(shippingInformation.pickingDate), shippingInformation.containerSealNumber);
        }
    };

    return <div className={"pallet-shipping-form__select"}>
        <Select id="shipping-information-select"
                data-testid={"ship-pallet-shipping-information-select"}
                value={shippingId}
                disabled={shippingInformationList.length === 0}>
            {loadingData &&
                <MenuItem value={"placeholder"} key={"shipping-information-loading"}>
                    <CircularProgress size={20} />
                    {t("components.pallet.shipping.informationLoading")}
                </MenuItem>}
            {!loadingData && shippingInformationList.length === 0 &&
                <MenuItem value={"placeholder"} key={"shipping-information-loading"}>
                    {t("components.pallet.shipping.informationNotFound")}
                </MenuItem>}
            {shippingInformationList.map(si =>
                <MenuItem data-testid={`shipping-information-select-item-${si.id}`}
                          onClick={() => handleOnSelect(si.id)}
                          key={si.id}
                          value={si.id}>
                    {`${moment(si.pickingDate).format("Y-MM-DD")}`}
                    {si.truckIdentifier === "" ? "" : si.containerSealNumber === "" ? ` (${si.truckIdentifier})` : ` (${si.truckIdentifier}`}
                    {si.containerSealNumber === "" ? ""
                        : si.truckIdentifier === "" ? ` (${t("components.pallet.shipping.containerSealNumberShort")}: ${si.containerSealNumber})`
                        : `, ${t("components.pallet.shipping.containerSealNumberShort")}: ${si.containerSealNumber})`}
                </MenuItem>)}
        </Select>
    </div>;
};
