import { parcelsService } from "../../../../services/parcels";
import { groupBy, map } from "lodash";
import { action, computed, observable } from "mobx";
import { t } from "i18next";
import { DeliveryOrder } from "../../../../model/delivery-order";
import { BulkParcelUploadImportLine } from "../../../../model/bulk-parcel-upload-import-line";
import { BulkParcelUploadDeliveryOrderUpdateProcessor } from "./bulk-parcel-upload-delivery-order-update-processor";
import { stocksService } from "../../../../services/stocks";
import { BulkParcelUploadPalletProcessor } from "./bulk-parcel-upload-pallet-processor";
import { Pallet } from "../../../../model/pallet";
import { operationContext } from "../../../../../context/operation-context";
import { CreatePalletHandler } from "../../../../command/create-pallet/create-pallet-handler";
import { PalletAddParcelHandler } from "../../../../command/pallet-add-parcel/pallet-add-parcel-handler";
import { CreatePalletCommand } from "../../../../command/create-pallet/create-pallet-command";
import { PalletAddParcelCommand } from "../../../../command/pallet-add-parcel/pallet-add-parcel-command";

export class BulkParcelUploadImporter {

    private _canceled: ((value?: (PromiseLike<void> | void)) => void) | null;

    @observable
    private _deliveryOrdersToSave: DeliveryOrder[] = [];

    @observable
    private _palletsToSave: Pallet[] = [];

    @observable
    private _savedDeliveryOrders: DeliveryOrder[] = [];

    @observable
    private _savedPallets: Pallet[] = [];

    @observable
    private _errors: string[] = [];

    private readonly _deliveryOrderProcessor = new BulkParcelUploadDeliveryOrderUpdateProcessor();
    private readonly _palletProcessor = new BulkParcelUploadPalletProcessor();
    private readonly _createPalletHandler = new CreatePalletHandler();
    private readonly _palletAddParcelHandler = new PalletAddParcelHandler();

    public async cancel(): Promise<void> {
        if (this.processingIsFinishedOrNotStarted) {
            return Promise.resolve();
        }
        return new Promise((resolve => {
            this._canceled = resolve;
        }));
    }

    private get processingIsFinishedOrNotStarted() {
        return this._canceled ||
            this._errors.length > 0 ||
            this._deliveryOrdersToSave.length === this._savedDeliveryOrders.length;
    }

    @action
    public reset() {
        this._canceled = null;
        this._deliveryOrdersToSave = [];
        this._palletsToSave = [];
        this._errors = [];
        this._savedDeliveryOrders = [];
        this._savedPallets = [];
    }

    public get numberOfDeliveryOrdersToProcess() {
        return this._deliveryOrdersToSave.length;
    }

    public get numberOfProcessedDeliveryOrders() {
        return this._savedDeliveryOrders.length;
    }

    public get numberOfPalletsToProcess() {
        return this._palletsToSave.length;
    }

    public get numberOfProcessedPallets() {
        return this._savedPallets.length;
    }

    public get numberOfFailedDeliveryOrders() {
        return this._errors.length;
    }

    @computed
    public get errorList(): ReadonlyArray<string> {
        return this._errors as ReadonlyArray<string>;
    }

    @action
    public async import(orders: Map<number, DeliveryOrder>, importDataLines: BulkParcelUploadImportLine[]): Promise<void> {
        this.reset();

        this._deliveryOrdersToSave = map(groupBy(importDataLines, p => p.deliveryOrderId), (parcelLines, deliveryOrderId) => {
            const deliveryOrder = orders.get(Number(deliveryOrderId));
            if (deliveryOrder === undefined) {
                throw new Error(t("error.deliveryOrder.notFoundInOperation", {deliveryOrder: deliveryOrderId}));
            }

            return this._deliveryOrderProcessor.process(deliveryOrder, parcelLines);
        }).filter(this.isOrderAltered);

        try {
            for (const deliveryOrder of this._deliveryOrdersToSave) {
                if (this._canceled != null) {
                    this._canceled();
                    return;
                }
                await this.saveDeliveryOrder(deliveryOrder);
                this.deliveryOrderProcessed(deliveryOrder);
            }
        } catch (e) {
            this.addError(e.toString());
        }

        const palletizationLines = importDataLines.filter(line => line.palletIndexOrEmpty !== "" || line.palletCodeOrEmpty !== "");
        this._palletsToSave = map(groupBy(palletizationLines, p => `${p.palletIndex}${p.palletCode}`),
                palletLines => this._palletProcessor.process(palletLines,
                    palletLines.map(line => orders.get(line.deliveryOrderId)).flatMap(d => d?.parcels ?? [])));

        try {
            for (const pallet of this._palletsToSave) {
                if (this._canceled != null) {
                    this._canceled();
                    return;
                }
                await this.savePallet(pallet);
            }
        } catch (e) {
            this.addError(e.toString());
        }
    }

    private isOrderAltered(deliveryOrder: DeliveryOrder) {
        return deliveryOrder.cancelledParcels.length > 0 ||
            deliveryOrder.requestedStockouts.length > 0 ||
            deliveryOrder.createdParcels.length > 0 ||
            deliveryOrder.parcels.filter(parcel => parcel.hasParcelTrackingDataAltered) !== undefined;
    }

    @action
    private deliveryOrderProcessed(deliveryOrder: DeliveryOrder) {
        this._savedDeliveryOrders.push(deliveryOrder);
    }

    @action
    private addError(e: string) {
        this._errors.push(e);
    }

    private async saveDeliveryOrder(deliveryOrder: DeliveryOrder): Promise<void> {
        await Promise.all(deliveryOrder.cancelledParcels
            .map(parcel => parcelsService.deleteParcelById(
                deliveryOrder.operationCode,
                deliveryOrder.batchId,
                parcel.id,
            )));

        await Promise.all(deliveryOrder.requestedStockouts
            .map(stockout => stocksService.saveStockout(stockout)));

        for (const parcel of deliveryOrder.createdParcels) {
            const parcelId = await parcelsService.createParcel(
                deliveryOrder.operationCode,
                parcel,
                false);
            parcel.setParcelId(parcelId);
        }

        await Promise.all(deliveryOrder.parcels
            .filter(parcel => parcel.hasParcelTrackingDataAltered)
            .map(parcel => parcelsService.announceParcelTracker(deliveryOrder.operationCode, deliveryOrder.batchId, parcel.parcelId, parcel.parcelTracker, parcel.carrier)));
    }

    private async savePallet(pallet: Pallet): Promise<void> {
        if (pallet.status === "New") {
            this._savedPallets.push(await this.createPallet(operationContext.operation!.warehouseId, pallet));
        } else if (pallet.parcels.some(p => p.palletCode === undefined)) {
            this._savedPallets.push(await this.fillPallet(pallet));
        }
    }

    private async createPallet(warehouseCode: string, pallet: Pallet) {
        return await this._createPalletHandler.handle(new CreatePalletCommand(warehouseCode, pallet.carrier,
            pallet.countryGroup, pallet.parcels, pallet.maxParcelWeight, pallet.maxWeight, pallet.creationComment,
            pallet.externalId?.split(",")?.map(s => s.trim())));
    }

    private async fillPallet(pallet: Pallet) {
        return (await this._palletAddParcelHandler.handleMultiple(
            pallet.parcels.filter(p => p.palletCode === undefined)
                .map(p => PalletAddParcelCommand.createWithParcelId(pallet, p.id)),
        ))[0];
    }
}
