import moment from "moment";
import { Guid } from "guid-typescript";

export interface IPalletShippingInformation {
    truckIdentifier: string;
    containerSealNumber: string;
    pickingDate: moment.Moment;
}

export class PalletShippingInformation {
    private _id: string;
    private _truckIdentifier: string;
    private _containerSealNumber: string;
    private _pickingDate: moment.Moment;

    constructor(palletShippingInformation: IPalletShippingInformation) {
        this._id = Guid.create().toString();
        this._truckIdentifier = palletShippingInformation.truckIdentifier;
        this._containerSealNumber = palletShippingInformation.containerSealNumber;
        this._pickingDate = palletShippingInformation.pickingDate;
    }

    public get id() {
        return this._id;
    }

    public get truckIdentifier() {
        return this._truckIdentifier;
    }

    public get containerSealNumber() {
        return this._containerSealNumber;
    }

    public get pickingDate() {
        return this._pickingDate;
    }
}
